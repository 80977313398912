.feeds_feed {
  .field {
    line-height: 2rem;
  }
}

.path-feed {
  table.responsive-enabled {
    width: 100%;
    line-height: 2rem;

    th, td {
      border: 1px solid;
    }
  }
}
