.block {
  &.block-bior-footer-contact {
    text-align: center;

    .extraInfo {
      margin-top: 16px;
    }

    .contactUs {
      a {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;

        &.facebook {
          svg {
            width: 44px;
            height: 44px;
          }

          &:hover {
            svg {
              .st9 {
                fill: #004f51;
              }
            }
          }
        }

        &.sazinies {
          color: #88d1ce;
          text-decoration: none;
          padding: 12px 10px;
          border: 2px solid #88d1ce;
          text-transform: uppercase;
          margin-left: 5px;
          letter-spacing: 2px;

          &:hover {
            color: #004f51;
            border-color: #004f51;
          }
        }
      }
    }
  }
}

@media (min-width: 800px) {
  .block {
    &.block-bior-footer-contact {
      text-align: right;
      margin-top: 10px;
    }
  }
}
