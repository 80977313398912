@font-face {
  font-family: 'FontAwesome';
  src: url('../fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

$default-font-family: 'Open Sans', sans-serif;

$colors: (
        white: #fff,
        primary: #2a973f,
        blue: #2F9AD0,
        red: red,
        url-color: #191919,
        url-hover: #a5a5a5,
        font-color: #313131,
        dark-green: #004f51,
        light-blue: #88d1ce,
        brown: #b25d5b,
        gray: #bcbec0,
        light-gray: #f6f5f5,
) !default;
