.product-two-columns {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 2px solid #88d1ce;
  padding: 11px 0;
  margin: 0 auto;
  max-width: 740px;

  .product-column-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      border-bottom: 2px solid #b25d5b;
    }
  }

  .field--name-attribute-metodes-akreditacija {
    font-style: italic;
    margin: 0 !important;
  }

  .views-field-title {
    width: 100%;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    transition: font-weight 0.3s;
    padding: 9px 20px 9px 25px;

    @media (min-width: 800px) {
      padding: 9px 20px 9px 45px;
    }

    &:before {
      position: absolute;
      content: '';
      width: 14px;
      height: 2px;
      background: #191919;
      top: 18px;
      left: 0;
      transition: background-color 0.3s, transform 0.5s;

      @media (min-width: 800px) {
        left: 20px;
      }
    }

    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 14px;
      background: #191919;
      top: 12px;
      left: 6px;
      transition: background-color 0.3s, transform 0.5s;

      @media (min-width: 800px) {
        left: 26px;
      }
    }
  }

  .view-cenradis & {
    .views-field-title {
      @media (min-width: 800px) {
        padding-right: 165px;
      }
    }
  }

  .views-field-purchased-entity,
  .views-field-variations,
  .views-field-field-kategorija,
  .views-field-field-dzivnieka-veids,
  .views-field-field-slim,
  .field--name-variations {
    width: 100%;
    line-height: 1.33;
    font-size: 14px;
    font-weight: 300;
    padding: 0;

    @media (min-width: 740px) {
      padding: 0 20px 0 45px;
    }

    & > .field-content,
    & > .field__item {
      width: 100%;
    }

    & > .field__item {
      &:not(:last-child) {
        .product-column-wrapper {
          border-bottom: 2px solid #b25d5b;
        }
      }
    }

    .views-label-field-kategorija,
    .views-label-field-dzivnieka-veids,
    .views-label-field-slim {
      font-weight: 600;
    }

    .product-left-column {
      width: 66.6%;
      padding-right: 20px;
      padding-bottom: 10px;
      padding-top: 10px;

      @media (max-width: 740px - 1) {
        width: 100%;
      }

      .field__label {
        font-weight: 600;
        margin: 10px 0 0 0;
      }

      .field__item {
        margin: 10px 0 0 0;
        line-height: 1.33;
        font-size: 14px;
        font-weight: 300;
      }
    }

    .product-right-column {
      width: 33.3%;
      padding-left: 20px;
      border-left: 2px solid #b25d5b;
      padding-bottom: 10px;
      padding-top: 10px;

      @media (max-width: 740px - 1) {
        width: 100%;
      }

      .field--name-price {
        display: inline-block;
      }

      .product-add-to-cart {
        margin: 10px 0 0 0;
      }

      .price-comment {
        margin: 10px 0 0 0;

        div {
          display: inline;
        }
      }
    }
  }

  .views-field-field-kategorija,
  .views-field-field-dzivnieka-veids,
  .views-field-field-slim {
    line-height: 2;
  }


  &.open {
    .views-field-title {
      &:before, &:after {
        background-color: #b25d5b;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }

    .views-field-variations,
    .views-field-field-kategorija,
    .field--name-variations {
      max-height: 5000px;
      transition: max-height 0.3s ease-in;
    }

    .dropbutton-wrapper {
      @media (max-width: 800px - 1) {
        margin: 20px 0;
      }
    }
  }

  .views-field-remove-button {
    position: absolute;
    right: 0;
    top: 11px;

    &:before {
      position: absolute;
      display: inline-block;
      color: white;
      top: 9px;
      left: 17px;
      font-size: 16px;
      pointer-events: none;

      @media (min-width: 800px) {
        content: "x";
      }
    }

    .views-label {
      display: none;
    }

    .delete-order-item {
      display: inline-block;
      line-height: 1;
      padding: 10px;
      height: 36px;
      box-sizing: border-box;
      background: color(brown);
      color: color(white);
      box-shadow: none;
      border: none;
      outline: none;

      @media (max-width: 800px - 1) {
        font-size: 14px;
      }

      @media (min-width: 800px) {
        padding: 10px 35px;
      }

      &:hover, &:active, &:focus {
        background: darken(color(gray), 10%);
      }
    }
  }

  .dropbutton-wrapper {
    @media (min-width: 800px) {
      position: absolute;
      top: 15px;
      right: 0;
      margin: 0;
    }
  }
}

.view-cenradis, .view-commerce-cart-form {
  .product-two-columns {
    .views-field-purchased-entity,
    .views-field-variations,
    .views-field-field-kategorija,
    .views-field-field-dzivnieka-veids,
    .views-field-field-slim,
    .field--name-variations {
      max-height: 0;
      transition: max-height 0.3s ease-out;
      overflow: hidden;
    }

    &.open {
      .views-field-title {
        &:before, &:after {
          background-color: #b25d5b;
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
      }

      .views-field-purchased-entity,
      .views-field-variations,
      .views-field-field-kategorija,
      .views-field-field-dzivnieka-veids,
      .views-field-field-slim,
      .field--name-variations {
        max-height: 5000px;
        transition: max-height 0.3s ease-in;
      }
    }
  }
}

//open product template
.product-single {
  .views-field-title {
    pointer-events: none;
  }
}

//cart template
.view-commerce-cart-form {
  thead {
    display: none;
  }

  .views-field-title {
    padding-right: 80px;
    @media (min-width: 800px) {
      padding-right: 140px;
    }
  }

  .add-to-cart-link {
    display: none;
  }
}
