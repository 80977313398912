.region-sidebar-second {
  padding: 20px;
  background-color: rgb(136, 209, 206);
}

@media (min-width: 600px) {
  .region-sidebar-second {
    padding: 50px 50px 20px 50px;
  }
}
