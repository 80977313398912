.block {
  &.block-views-blockfront-4-bloki-block-1 {
    margin: 30px 0;

    &:before,
    &:after {
      content: "";
      display: table;
      clear: both;
    }

    a {
      text-decoration: none;
    }

    .views-row {
      box-sizing: border-box;
      padding: 20px 15px;
      background: #fff;
      transition: background-color 0.5s ease;

      .views-field-title,
      .views-field-field-link{
        display: table;
        width: 90%;
        margin: 0 auto;

        span,
        .field-content{
          display: table-row;
        }

        a {
          display: table-cell;
          width: 100%;
          height: 56px;
          box-sizing: border-box;
          padding: 2px 5px;
          line-height: 1em;
          vertical-align: middle;
          text-align: center;
          border: 2px solid #006265;
          font-weight: 400;
          color: #006265;
          transition: border 0.5s ease, color 0.5s ease;
        }
      }

      path {
        transition: fill 0.5s ease;
      }

      &:hover {
        background-color: #88d1ce;

        .views-field-title,
        .views-field-field-link{
          a {
            border-color: #fff;
            color: #fff;
          }
        }

        .st0,
        .st1,
        .st2 {
          fill: #fff;
        }
      }
    }
  }
}


@media (min-width: 400px) {
  .block {
    &.block-views-blockfront-4-bloki-block-1 {
      .views-row {
        display: inline-block;
        width: 50%;
        float: left;
      }
    }
  }
}

@media (min-width: 800px) {
  .block {
    &.block-views-blockfront-4-bloki-block-1 {
      margin: 0;

      .views-row {
        width: 25%;
        float: left;
        padding: 40px 15px 68px 15px;
      }
    }
  }
}
