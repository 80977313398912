@mixin button-large($bg) {
  display: inline-block;
  text-transform: uppercase;
  background: $bg;
  font-size: 14px;
  line-height: 1.4;
  color: color(white);
  text-align: center;
  text-decoration: none;
  font-weight: normal;
  padding: 14px 45px;
  box-shadow: none;
  border: none;

  &:hover, &:focus, &:active {
    background: darken($bg, 10%);
  }

  @media (min-width: 980px) {
    font-size: 18px;
  }
}
