#block-biorheaderbutton {
  width: 100%;

  #bior-custom-block-header-button {
    display: inline-block;
    background-color: #88d1ce;
    padding: 16px 20px;
    text-align: center;
    min-width: 170px;
    color: #fff;
    text-decoration: none;
    @include transition(background-color, 0.3s, ease);

    &:hover {
      background-color: #024e53;
    }
  }

  @media (min-width: 1220px) {
    width: auto;

    #bior-custom-block-header-button {
      display: block;
    }
  }
}
