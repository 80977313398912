.block {
  &.block-views-blocklizigie-jaunumi-block-1,
  &.block-views-blocklizigie-jaunumi-block-2 {
    margin-top: 20px;

    .views-row {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #004f51;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }

      .views-field-field-image {
        text-align: center;
        margin-bottom: 10px;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      .views-field-title {
        a {
          text-decoration: none;
          line-height: 1.33;
        }
      }
    }
  }
}
