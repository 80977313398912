.dropbutton-wrapper {

}

.js .dropbutton-multiple {
  margin-top: 10px;

  .dropbutton-action {
    min-width: 80px;
  }

  .dropbutton-widget {
    background-color: color(gray);
    border: none;
  }

  .dropbutton {
    color: color(white);

    li {
      border: none;
    }
  }

  a {
    text-decoration: none;
    color: color(white);
    font-weight: 300;
    padding: 5px;

    &:hover, &:active, &:focus {
      text-decoration: underline;
    }
  }
}
