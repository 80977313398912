.view-commerce-cart-form {
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  padding-bottom: 50px;

  .form-actions {
    display: inline-block;
    margin: 0;

    @media (min-width: 800px) {
      float: right;
    }

    .form-submit:not(.empty-cart-button) {
      display: none;
    }

    .empty-cart-button {
      @include button-large(color(light-blue));
      margin: 30px 30px 0  0;
    }
  }

  .link-print-pdf {
    @include button-large(color(brown));
    margin-top: 30px;

    @media (min-width: 800px) {
      float: right;
    }
  }
}
