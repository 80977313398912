.eu-cookie-compliance-banner {
  padding: 15px 0;

  .popup-content {
    display: inline-block;
    float: none;
    max-width: 100%;

    .eu-cookie-compliance-message {
      h2, p, a {
        font-weight: 300;
      }
    }

    .eu-cookie-compliance-buttons {
      display: inline-block;
      float: none;
      margin: 5px 0;

      button {
        margin: 0 5px;
        border-radius: 0;
        background: #fff;
        transition: background 0.3s, color 0.3s;
        border: none;
        color: #006265;
        box-shadow: none;
        font-weight: 300;
        text-shadow: none;

        &:hover {
          background: #006265;
          color: #fff
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .eu-cookie-compliance-banner {
    max-width: 1400px;
    padding: 15px 50px;
    margin: 0 auto;
  }
}
