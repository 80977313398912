.block-facet-blockkategorija {
  clear: both;

  @media (max-width: 980px - 1) {
    width: 100%;
    float: left;
  }

  .facets-widget-checkbox {
    position: relative;

    ul {
      position: relative;
      padding: 0;
      z-index: 3;

      @media (max-width: 980px - 1) {
        width: 100%;
        float: left;
      }
    }

    > ul {
      padding-top: 90px;
      margin-top: 0;
      margin-bottom: 0;

      @media (min-width: 980px) {
        padding-top: 70px;
      }

      > li  {
        // First level nav items
        > .facets-widget- {
          position: relative;
        }

        > label {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 14px;
          color: color(white);
          width: 50%;
          z-index: 3;
          display: block;
          padding: 24px 0 24px;
          text-align: center;
          line-height: 1.2;

          @media (min-width: 800px) {
            font-size: 22px;
          }
        }

        &:last-child {
          > label {
            right: 0;
            left: auto;
          }
        }

        &.facet-item--expanded >  label {
          background: color(dark-green);
        }

        > label {
          background: color(light-blue);
        }
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    .facet-item  {
      list-style-type: none;
      list-style-image: none;
      margin: 0;

      .facet-item {
        label {
          border-bottom: 1px solid #e6e7e8;
          cursor: pointer;

          &:hover {
            background: colors(light-gray);
          }
        }

        &:last-child {
          label { border-bottom: none; }
        }
      }

      a {
        text-decoration: none;
      }
    }

    .facet-item--active-trail > .facets-widget- {
      &:before {
        display: none;
      }

      &.dropdown-visible {
        &:before {
          display: inline-block;
        }
      }
    }

    .facets-widget-{
       height: 50px;

       &:before {
         width: 100%;
         height: 50px;
         background: white;
         content: "";
         display: inline-block;

         @media (min-width: 980px) {
           width: 410px;
         }
       }

       &:after {
         cursor: pointer;
         z-index: 3;
         position: absolute;
         top: 0;
         right: 0;
         height: 50px;
         display: inline-block;
         content: "";
         background: url("/modules/custom/bior_custom/images/bior-icon-cenas-down.svg") no-repeat center right 15px;
         width: calc(100% - 50px);

        @media (min-width: 980px) {
          left: 50px;
          width: 360px;
        }
       }

       label {
         width: 100%;
         @media (min-width: 980px) {
           width: 410px;
         }
       }

       .facet-item {
         > label {
           background: color(white);
           display: none;
         }

         &.facet-item--expanded {
           > label {
             display: block;
             font-size: 16px;
             padding: 17px 30px 17px 30px;

             &:before {
               display: inline-block;
               background: color(white);
               color: color(brown);
               font-size: 18px;
               position: absolute;
               top: 0;
               left: 0;
               content: "\2716";
               z-index: 2;
               width: 30px;
               height: 50px;
               text-align: center;
               padding: 0;
               line-height: 50px;
             }
           }
         }

         &:not(.facet-item--expanded) > a.is-active {
           width: 100%;
           padding: 17px 10px;
           font-size: 16px;
           display: block !important;
           background: color(white);

           &:before {
             display: inline-block;
             background: color(white);
             color: color(brown);
             font-size: 18px;
             position: absolute;
             top: 0;
             left: 0;
             content: "\2716";
             z-index: 2;
             width: 30px;
             height: 50px;
             text-align: center;
             padding: 0;
             line-height: 50px;
           }

           @media (min-width: 980px) {
             width: 410px;
           }
         }
       }

        > ul > li {
          font-size: 0;
        }

        &.dropdown-visible  {

          @media (max-width: 980px - 1) {
            .facets-widget- { display: none; }
          }

          &:after {
            z-index: 6;
            background: url("/modules/custom/bior_custom/images/bior-icon-cenas-up.svg") no-repeat center right 15px;

            @media (min-width: 980px) {
              background-color: color(white);
              left: 0;
              width: 410px;
              display: inline-block;
            }
          }


            &:before {
              @media (min-width: 980px) {
                position: absolute;
              }
            }

            > ul {
              z-index: 4;

              @media (max-width: 980px - 1) {
                top: -2px;
              }

              @media (min-width: 980px) {
                padding-top: 50px;
              }

              &:after {
                z-index: 6;
              }

              > li {
                font-size: 16px;

                > label {
                  padding: 13px 10px;
                  display: block;
                  box-shadow: 0px 0px 2px #ddd;
                  position: relative;
                  z-index: 5;
                }
              }

              > .facet-item.facet-item--expanded {
               > label {
                 padding: 13px 10px;

                 &:before {
                   display: none;
                 }
               }
             }
          }
        }
     }
  }



  // first select
  .facets-widget-checkbox > ul > .facet-item--expanded > .facets-widget- {
    margin-top: 20px;
    z-index: 4;

    @media (max-width: 980px - 1) {
      position: relative;
      width: 100%;
      clear: both;
      background: color(white);
    }

    @media (min-width: 980px) {
      margin-left: 20px;
    }
  }

  // second select
   .facets-widget- > ul > .facet-item--expanded > .facets-widget- {
    position: relative;
    z-index: 3;

     @media (max-width: 980px - 1) {
       position: relative;
       width: 100%;
       clear: both;
       margin-top: 20px;
       background: color(white);
     }

     @media (min-width: 980px) {
       position: absolute;
       right: 20px;
       top: 0;
     }
   }

   // third select
   .facets-widget- > ul > .facet-item--expanded > .facets-widget- > ul > .facet-item--expanded > .facets-widget- {
    z-index: 2;

     @media (max-width: 980px - 1) {
       position: relative;
       width: 100%;
       clear: both;
       margin-top: 20px;
       background: color(white);
     }

     @media (min-width: 980px) {
       position: absolute;
       left: -430px;
       top: 65px;
     }
   }

      // fourth select
   .facets-widget- > ul > .facet-item--expanded > .facets-widget- > ul > .facet-item--expanded > .facets-widget- > ul > .facet-item--expanded > .facets-widget- {
    z-index: 1;

     @media (max-width: 980px - 1) {
       position: relative;
       margin-top: 20px;
       width: 100%;
       clear: both;
       background: color(white);
     }

     @media (min-width: 980px) {
       position: absolute;
       right: -20px;
       left: auto;
       top: 0;
     }
   }
}
