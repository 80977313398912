body.page-node-type-notikums {
  main {
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;

    article {
      box-sizing: border-box;
      padding: 0 10px;
      max-width: 740px;
      margin: 0 auto;
      position: relative;
      line-height: 2em;
    }
  }
}

@media (min-width: 900px) {
  body.page-node-type-notikums {
    main {
      padding: 0 50px;
    }

    .layout-content {
      box-sizing: border-box;
      width: 100%;
      float: left;
      margin: 0 -350px 0 0;
      padding: 0 400px 0 0;
    }

    .layout-sidebar-first {
      box-sizing: border-box;
      width: 350px;
      float: left;
      padding: 0;
    }
  }
}
