.region-header {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px 0;
  box-sizing: border-box;
  position: relative;
  text-align: center;

  > div {
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .region-header{
    .block-bior-menu{
      margin-left: 5px;
    }
  }
}

@media (min-width: 1220px) {
  .region-header {
    align-items: stretch;
    flex-direction: row;
    flex-flow: initial;
    justify-content: initial;

    > div {
      position: relative;
      margin-right: 20px;
      margin-bottom: 0;
      align-items: center;
      display: flex;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
