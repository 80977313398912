@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box; /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin transition($property, $duration, $easing: linear) {
  transition: $property $duration $easing;
  -webkit-transition: $property $duration $easing;
  -moz-transition: $property $duration $easing;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin box-shadow( $horiz : .5em , $vert : .5em , $blur : 0px , $spread : 0px , $color : #000000 ) {
  -webkit-box-shadow: $horiz $vert $blur $spread $color;
  -moz-box-shadow: $horiz $vert $blur $spread $color;
  box-shadow: $horiz $vert $blur $spread $color;
}
