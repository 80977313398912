.block {
  &.block-views-blockpage-header-image-block-1,
  &.block-views-blockinfrastrukturas-saraksts-block-2 {
    .view-page-header-image,
    .view-infrastrukturas-saraksts {
      display: none;

      img {
        display: block;
      }
    }

    .views-field-field-galvenes-attels {
      img {
        width: 100%;
        height: auto;
      }

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (min-width: 640px) {
  .block {
    &.block-views-blockpage-header-image-block-1,
    &.block-views-blockinfrastrukturas-saraksts-block-2 {
      .view-page-header-image,
      .view-infrastrukturas-saraksts {
        display: block;
      }
    }
  }
}
