.side-nav {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -10;
  -webkit-backface-visibility: hidden;

  &.side-nav--visible {
    z-index: 100;

    .side-nav__container {
      transform: none;
    }

    .customOverlay {
      opacity: 1;
    }

    &.side-nav--animatable {
      .side-nav__container {
        transition: transform 0.33s cubic-bezier(0, 0, 0.3, 1);
      }
    }
  }

  &.side-nav--animatable {
    .side-nav__container {
      transition: transform 0.13s cubic-bezier(0, 0, 0.3, 1);
    }
  }

  .side-nav__hide {
    position: absolute;
    left: 16px;
    top: 16px;
    background: none;
    border: none;
    color: #FFF;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
  }

  .side-nav__header {
    height: 200px;
    background: #EA2663;
    color: #FFF;
    display: flex;
    padding: 16px;
    align-items: flex-end;
    font-size: 24px;
  }

  .side-nav__content {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    li {
      height: 48px;
      line-height: 48px;
      padding: 0 16px;

      &:hover {
        background: #CCC;
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  .customOverlay {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    cursor: pointer;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    @include opacity(0);
    will-change: opacity;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
  }

  .sidebar-button-wrapper {
    display: inline-block;
    width: 100%;
    height: 25px;

    .sidebar-button {
      display: table;
      float: left;
      margin-right: 25px;

      svg{
        width: 20px;
        height: 23px;
        fill: #FFF;
      }

      span {
        display: table-cell;
        vertical-align: middle;
        padding-left: 12px;
      }
    }
  }

  .region-sidebar-over {
    display: inline-block;
    position: relative;
    width: 100%;
    clear: both;
    height: calc(100% - 25px);
  }

  .side-nav__container {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background: color(dark-green);
    height: 100%;
    box-shadow: 2px 0 12px rgba(0, 0, 0, 0.4);
    transform: translateX(100%);
    /*display: flex;*/
    /*flex-direction: column;*/
    display: block;
    will-change: transform;

    .side-nav__container-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  #closeSidebar {
    cursor: pointer;
    color: #fff;
    align-items: center;

    &:before {
      content: '';
      display: inline-block;
      width: 22px;
      height: 22px;
      background: url('../images/bior-sidebar-close.png');
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .20s ease-in-out;
      -moz-transition: .20s ease-in-out;
      -o-transition: .20s ease-in-out;
      transition: .25s ease-in-out;
    }

    &:hover:before {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }

  a {
    color: color(light-blue);

    &:hover {
      color: #fff;
    }
  }
}

@media (min-width: 500px) {
  .side-nav {
    .side-nav__container {
      padding: 20px 50px;
    }
  }
}


/* Styles when admin menu toolbar is visible
----------------------------------*/
body.user-logged-in.toolbar-fixed {
  &.toolbar-horizontal,
  &.toolbar-vertical {
    .side-nav {
      top: 39px;
      height: calc(100% - 39px);
    }
  }

  &.toolbar-horizontal {
    &.toolbar-tray-open {
      .side-nav {
        top: 79px;
        height: calc(100% - 79px);
      }
    }
  }
}
