.block.block-views-blockviens-galerijas-video-block-1 {
  .view-content {
    .views-row {
      width: 98%;
      margin: 0 0.5% 10px 0.5%;

      &:after {
        background: #fff url('../images/bior-icons-video.svg') no-repeat center center;
      }

      .views-field-field-video {
        text-align: center;

        img {
          max-width: 550px;
          height: auto;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .block {
    &.block-views-blocksakums-galeriju-bloki-block-1 {
      float: left;
      width: 66.66%;

      .view-content {
        .views-row {
          float: left;
          width: 49%;
          margin: 0 0.5% 10px 0.5%;

          .views-field-field-galerija-atteli {
            padding-right: 15px;
            box-sizing: border-box;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }

          &:last-child {
            .views-field-field-galerija-atteli {
              padding-right: 7.5px;
              padding-left: 7.5px;
            }
          }
        }
      }
    }

    &.block-views-blockviens-galerijas-video-block-1 {
      float: right;
      width: 33.33%;

      .view-content {
        .views-row {
          text-align: right;

          .views-field-field-video {
            display: inline-block;
            box-sizing: border-box;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
