.field--name-body,
.field--name-field-vadiba-saturs,
.field--type-text-long {
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  h2 {
    color: #b25d5d;
    font-size: 1.250em;
    font-weight: 800;
    margin: 20px 0 20px;
    padding-top: 20px;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      width: 50px;
      height: 5px;
      top: 5px;
      left: 0;
      background-color: #b25d5d;
    }
  }

  figure {
    margin: 20px 0;
    padding: 0;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    img {
      width: 100%;
      height: auto;
    }
  }

  figcaption {
    text-align: right;
    line-height: 1.5;
    box-sizing: border-box;
    font-style: italic;
    font-size: 0.750em;
    padding: 25px 15px;
    background-color: #eae7e7;
  }

  .tableOverflow {
    overflow-x: auto;
    width: 100%;
  }

  table {
    border: none;
    width: 100%;

    caption {
      font-size: 1.250em;
      color: #b25d5d;
      font-weight: 800;
      margin-bottom: 25px;
      text-align: center;
    }

    thead {
      background-color: #88d1ce;
      color: #fff;
    }

    tr {
      border: none;

      th, td {
        padding: 5px 10px 5px 20px;
        line-height: 1.38;
        border: none;
        border-bottom: 1px solid #88d1ce;
      }
    }

    tfoot {
      background-color: rgba(136, 209, 209, 0.2);

      td {
        border: none;
      }
    }
  }

  a {
    &:before {
      vertical-align: text-bottom;
      width: 22px;
      height: 22px;
      margin-right: 3px;
      display: inline-block;
    }

    &[href^="http://"],
    &[href^="https://"] {
      &:before {
        background: url('../images/icon-link.svg') no-repeat;
        content: '';
      }

      &.hide {
        &:before {
          display: none;
        }
      }
    }

    &[href^="http://bior.lv"],
    &[href^="http://www.bior.lv"] {
      &:before {
        display: none;
      }
    }

    &[href$=".docx"],
    &[href$=".doc"] {
      &:before {
        background: url('../images/icon-word.svg') no-repeat;
        content: '';
      }
    }

    &[href$=".xls"],
    &[href$=".xlsx"] {
      &:before {
        background: url('../images/icon-xls.svg') no-repeat;
        content: '';
      }
    }

    &[href$=".pdf"] {
      &:before {
        background: url('../images/icon-pdf.svg') no-repeat;
        content: '';
      }
    }
  }
}

@media (min-width: 600px) {
  .field--name-body,
  .field--name-field-vadiba-saturs {
    figure {
      padding: 0 0 0 150px;
    }

    figcaption {
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
    }
  }
}
