.layout-container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
}

.path-contact {
  .region-content {
    padding: 20px;
    background-color: rgb(136, 209, 206);
    margin-bottom: 15px;
  }
}

@media (min-width: 800px) {
  .path-contact {
    .region-content {
      padding: 100px;
    }
  }
}

@media (min-width: 900px) {
  .layout-container {
    max-width: 1400px;
    padding: 0 50px;
  }
}
