body.path-group {

  article {
    h2 {
      display: none;
    }

    .webform-submission-form {
      .messages {
        color: #000;
      }

      .form-type-select {
        display: inline-block;
      }

      .form-managed-file {
        margin-bottom: 30px;

        .button-action {
          color: #6A6A6C;
          border: 2px solid #8F9092;
          padding: 5px 20px;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 20px;

          &:before {
            display: none;
          }
        }

        .form-type-checkbox {
          margin: 0;

          input, label {
            display: inline-block;
            width: auto;

            a {
              color: #6A6A6C;
              font-style: italic;
              font-size: 14px;
              text-decoration: none;
            }
          }
        }

        .form-submit {
          clear: both;
          color: #000;
          position: static;
          width: auto;
          height: auto;
        }
      }
    }
  }

  table.views-table,
  table.responsive-enabled {
    width: 100%;

    thead {
      text-align: left;
      background-color: color(light-blue);

      th, td {
        color: #FFF;

        a {
          color: #FFF;
        }
      }
    }

    th, td {
      padding: 15px 5px;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    tr {
      border-bottom: 1px solid color(light-blue);

      td:last-child {
        width: 200px;
      }
    }
  }

  .group__content {
    box-sizing: border-box;
    padding: 0 10px;
    max-width: 740px;
    margin: 0 auto;
    position: relative;
    line-height: 2em;

    h2.main-group-title {
      font-size: 32px;
      color: #b25d5d;
      font-weight: 800;
      margin: 20px 0 20px;
      padding-top: 20px;
      position: relative;
      text-decoration: none;
    }
  }

  .group__content-wrapper__gray {
    width: 100%;
    background-color: #F6F5F5;
    padding: 60px 0;
    margin: 20px 0;

    .group__content-wrapper__gray-wrapper {
      box-sizing: border-box;
      padding: 0 10px;
      max-width: 740px;
      margin: 0 auto;
      position: relative;
      line-height: 2em;
    }
  }

  .field__label {
    color: #B4B4B4;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 14px;
  }

  .field__label-date {
    text-transform: none;
  }

  .node__submitted {
    display: none;
  }

  form.webform-submission-form {
    background: transparent;
    padding: 0;

    label {
      color: #6A6A6C;
    }

    .form-actions {
      margin: 0;
      text-align: left;
    }
  }

  .group__content-wrapper {

    .field--type-file {
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: 1px solid #CBCBCC;

      .field__item {
        display: inline-block;
        background-color: #63CEF4;
        font-weight: 600;
        color: #FFF;
        text-transform: uppercase;
        font-size: 14px;
        margin: 0 0 10px;

        .file {
          background-image: none;
          padding: 0;
          height: auto;
          width: auto;
        }

        a {
          display: block;
          padding: 5px 20px;
          color: #FFF;
          text-decoration: none;
        }
      }
    }
  }

  .user-register-form {
    .confirm-parent, .password-parent {
      width: 100%;
    }

    .password-confirm, .password-field, .password-strength, .password-confirm-match {
      max-width: 66em;
    }

    .form-item {
      label {
        display: inline-block;
        max-width: 33em;
        margin-bottom: 5px;
      }
    }

    .form-actions {

    }
  }
}

@media (max-width: 900px) {
  body.path-group {
    .user-register-form {
      .confirm-parent, .password-parent{
        width: 100%;
        max-width: 100%;
      }

      .form-item {
        label {
          width: 100%;
          max-width: 100%;
        }
      }

      .form-actions {

      }
    }
  }
}
