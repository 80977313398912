.region-highlighted {
  text-align: right;

  & > div {
    margin: 20px 0;
    display: inline-block;

    &.hidden {
      display: none;
    }
  }

  .messages {
    width: 100%;
    text-align: left;
  }
}
