.block-language {
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  text-align: right;

  ul.links {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-left: 10px;
      padding: 0;

      a {
        font-size: 1.250em;
        color: #004f51;
        text-decoration: none;
      }
    }
  }
}
