form.bef-exposed-form {
  .form-type-select,
  .form--inline {
    text-align: center;

    & > .form-type-select {
      margin: 10px 0;
      text-align: center;
      float: none;
    }

    & > .form-item,
    & > .form-actions {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
    }
  }

  label {
    font-size: 1.125em;
    transition: color 0.3s ease;
  }

  .form-select {
    width: 200px;
    border: none;
    border-bottom: 2px solid #b25d5d;
    outline: none;
    text-transform: uppercase;
    font-size: 14px;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
    padding: 5px 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('../images/bior-icons-select-arrow.svg') no-repeat 95% center;
  }

  .form-text {
    outline: none;
    padding: 5px 10px;
    font-size: 14px;
    border: transparent;
    border-bottom: 2px solid #b25d5d;
  }

  .form-actions {
    input.form-submit {
      background-color: #b25d5d;
      color: #FFF;
      border: none;
      padding: 5px 20px;
      outline: none;
    }
  }

  .form-item-gads,
  .form-item-type {
    vertical-align: inherit !important;
  }

  .bef-links {
    margin-right: 30px;
    margin-bottom: 10px;

    a {
      display: inline-block;
      position: relative;
      margin-right: 5px;
      text-decoration: none;
      font-size: 1.125em;

      &:after {
        display: list-item;
        position: absolute;
        content: '';
        top: 1px;
        right: -25px;
        font-size: 12px;
        color: #88d1ce;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      &.bef-link--selected {
        color: #191919;
        font-weight: 700
      }
    }

    &#edit-type {
      a {
        margin-right: 20px;
        margin-bottom: 5px;
      }
    }

    &#edit-gads {
      margin-right: 5px;

      a {
        text-transform: uppercase;
        color: #bebebe;
        font-size: 0.875em;
        transition: color 0.3s ease;

        &:hover {
          color: #191919;
        }

        &.bef-link--selected {
          color: #191919;
          font-weight: 700
        }

        &:after {
          position: relative;
          display: inline-block;
          content: " ";
          width: 1px;
          height: 20px;
          left: 0;
          top: 6px;
          background-color: #bebebe;
          bottom: -5px;
          margin: 0 0 0 10px;
        }

        &:last-child:after {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  form.bef-exposed-form {
    .form-type-select,
    .form--inline {
      & > .form-item,
      & > .form-actions {
        width: 100%;
        text-align: left;
      }
    }
  }
}
