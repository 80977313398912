.layout-content,
.galleries-section {
  box-sizing: border-box;
  padding: 0;
}

.block {
  position: relative;
}

.ajax-progress-throbber .throbber {
  background: transparent url('../images/loading.gif') no-repeat left center;
  display: inline-block;
  padding-left: 25px;
  box-sizing: border-box;
}

.views-field-field-svg-ikonas {
  text-align: center;
}

.views-field-field-svg-ikonas svg {
  max-width: 190px;
}

.view-id-sakuma_raksti .views-row:before,
.view-id-sakuma_raksti .views-row:after {
  content: "";
  display: table;
  clear: both;
}

.imageCell {
  text-align: left;
}

.imageCell img {
  max-width: 100%;
  height: auto;
}

.contentCell {
  padding: 10px 15px;
}

@media (min-width: 800px) {
  .newsTable {
    display: table;
    width: 100%;
  }
  .tableRow {
    display: table-row;
  }
  .tableData {
    display: table-cell;
    vertical-align: middle;
  }
  .imageCell {
    width: 350px;
  }
  .contentCell {
    padding: 10px 20px;
  }
}

article .node__content {
  box-sizing: border-box;
  padding: 0 10px;
  max-width: 740px;
  margin: 0 auto;
  position: relative;
  line-height: 2em;
}

.articleDate {
  box-sizing: border-box;
  padding: 0 10px;
  max-width: 740px;
  margin: 0 auto;
  position: relative;
  line-height: 2em;
}

#block-bior-theme-account-menu {
  clear: both;
}

.custom-info {
  letter-spacing: 2px;
}

.custom-info span {
  letter-spacing: 0;
}

.field--name-body ul {
  margin: 10px 0;
  padding: 0 0 0 20px;
}

.field--name-body ul li {
  list-style-image: url('../images/bior-bullet-red.svg');
  position: relative;
  margin: 3px 0;
  padding-left: 10px;
}

.field--name-body ul li li {
  list-style-image: url('../images/bior-bullet-blue.svg');
}

.field--name-body li {
  border-bottom: none;
}

#block-pagetitle {
  margin-bottom: 20px;
}

.path-frontpage #block-pagetitle {
  margin: 0;
}

.block-views-blockpublikacijas-autori-saraksts-block-1 {
  box-sizing: border-box;
  max-width: 740px;
  padding: 0 10px;
  margin: 30px auto 40px auto;
  text-align: left;
  font-size: 0.875em;
  font-weight: 300;
}

.block-views-blockpublikacijas-autori-saraksts-block-1 .views-row {
  line-height: 1.5;
}

.block-views-blockpublikacijas-autori-saraksts-block-1 h2 {
  margin: 0 0 5px 0;
  color: #b4b4b4;
  padding: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 300;
}

#block-biornodepublisheddate {
  box-sizing: border-box;
  max-width: 740px;
  margin: 0 auto 20px auto;
  padding: 0 10px;
  clear: both;
  float: none;
}

#block-biornodepublisheddate .clearfix {
  font-size: 0.875em;
  text-transform: lowercase;
  color: #b4b4b4;
}

article .field--name-field-publikacijas-pilna-versija a {
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  background-color: #68cef2;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875em;
  transition: background-color 0.5s ease;
}

article .field--name-field-publikacijas-pilna-versija {
  margin-bottom: 30px;
  margin-top: 20px;
}

article .field--name-field-publikacijas-pilna-versija a:hover {
  background-color: #176a88;
}

nav.pager .pager__item {
  padding: 0;
  margin: 1px;
}

nav.pager .pager__item a {
  box-sizing: border-box;
  display: inline-block;
  color: #191919;
  padding: 11px 0 0 0;
  text-decoration: none;
  width: 44px;
  border: 1px solid transparent;
  height: 45px;
  text-align: center;
  font-weight: 400;
}

nav.pager .pager__item.is-active a {
  border: 2px solid #191919;
}

#views-exposed-form-sakuma-raksti-block-2 .form--inline {
  display: none;
}

.fileAttachments {
  text-align: center;
  margin-top: 15px;
}

.fileAttachments > div {
  display: inline-block;
  color: #fff;
  margin: 0 20px 20px 20px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.fileAttachments > div:hover {
  color: #f6f5f5;
}

.beforeIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  box-sizing: border-box;
  margin-right: 5px;
  cursor: pointer;
}

.spanText {
  vertical-align: middle;
}

.beforeIcon:before {
  position: absolute;
  content: '';
  display: block;
  width: 16px;
  height: 2px;
  left: 3px;
  top: 10px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
}

.beforeIcon:after {
  position: absolute;
  content: '';
  display: block;
  width: 2px;
  height: 16px;
  left: 10px;
  top: 3px;
  background-color: #fff;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
}

.beforeIcon.selected:before,
.beforeIcon.selected:after {
  transform: rotateZ(-45deg);
}

p[align="left"] img,
img.align-left {
  margin: 0 10px 10px 0;
}
.page-node-113 #block-bior-theme-content {
  margin-bottom: 20px;
}

.view-header .selectYear {
  width: 200px;
  border: none;
  border-bottom: 2px solid #b25d5d;
  outline: none;
  text-transform: uppercase;
  font-size: 14px;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 5px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../images/bior-icons-select-arrow.svg') no-repeat 95% center;
}

.articleDate {
  color: #bebebe;
  font-size: 0.875em;
  margin-bottom: 20px;
}

.hiddenGroup {
  display: none;
}

.messages--status {
  box-shadow: none;
  border: none;
  background: rgba(136, 209, 206, 04);
  width: 100%;
  clear: both;
  margin: 15px auto;
  text-align: left;
}

#block-views-block-galerijas-bildes-block-1 {
  clear: both;
}

.page-node-872 #block-bior-theme-content article em {
  color: #cbcbcc;
}

.resize-vertical {
  min-height: 150px;
  height: 150px;
}

.ajax-progress-fullscreen {
  background-image: url('../images/loading.gif');
  background-color: transparent;
}

article.node--type-vadiba.node--view-mode-full,
article.node--type-doktors-doktoranturs.node--view-mode-full {
  max-width: 740px;
  margin: 0 auto;
}

ul.menu a.is-active {
  font-weight: 700;
}

div.small-caps {
  color: #fff;
  font-style: italic;
  font-size: 12px;
  margin-top: 10px;
}

table h5 {
  font-weight: 400;
}

table th h5 {
  font-weight: 600;
}

.views-field-field-svg-ikona {
  max-width: 190px;
  margin: auto;
}

.bior-custom-block-position {
  position: relative;
  display: table-cell;
  vertical-align: middle;
}

span.custom-icon-eye-arrow {
  display: inline-block;
  width: 15px;
  height: 30px;
  background: url('../images/bior-header-eye-arrow.svg') no-repeat center center;
}

@media (max-width: 900px) {
  .bior-custom-block-position {
    display: inline-block;
    vertical-align: middle;
  }
}

.mapboxgl-ctrl-attrib a:hover {
  color: rgba(0,0,0,.75);
}

footer .footer-two .left-side .footer-sazinies-button a.sazinies {
  color: #fff;
  border: 0;
}
