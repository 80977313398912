.block {
  &#block-bior-theme-branding {
    width: 100%;
    margin-right: auto;


    .bior-custom-logo-svg {
      display: block;
      width: 300px;
      max-width: 100%;
      height: auto;
      min-height: 94px;
      margin: 0 auto;
      text-align: center;
      background-color: transparent;
      background-position: center top;
      background-repeat: no-repeat;
    }

    @media (min-width: 1220px) {
      width: auto;

      .bior-custom-logo-svg {
        margin: 0;
      }
    }

    .bior-custom-logo-svg-lv {
      background-image: url("../images/bior_logo_lv.svg");
    }

    .bior-custom-logo-svg-en {
      background-image: url("../images/bior_logo_en.svg");
    }

    .bior-custom-logo-svg-ru {
      background-image: url("../images/bior_logo_ru.svg");
    }
  }
}
