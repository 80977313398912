.comment-wrapper {
  * {
    box-sizing: border-box;
  }

  .field--type-text-long {
    .form-item {
      label.form-required {
        display: none;
      }
    }
  }

  .form-item {
    textarea {
      background-color: #F6F5F5;
      border: transparent;
      resize: none;
    }
  }

  .title {
    text-transform: uppercase;
    font-size: 18px;
    width: 100%;
    font-weight: 600;
    line-height: 32px;
    color: #77cbc7;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .messages--status {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  .indented {
    display: block;
    clear: both;
  }

  .links.inline {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;

    a {
      color: #77cbc7;
      text-decoration: none;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }

  .filter-wrapper {
    display: none;
  }

  .form-actions {
    .form-submit {
      text-decoration: none;
      text-transform: uppercase;
      margin-right: 5px;
      padding: 5px 10px;
      outline: none;
      border: none;
    }

    .button--primary {
      color: #FFF;
      background-color: #B45C5B;
      padding: 12px 30px;
    }
  }
}

.comment {
  display: block;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #cbcbcc;
  margin: 0 0 40px;
  padding: 0;

  .content {
    padding-bottom: 40px;
    display: block;
    width: 100%;

    .field__item {
      margin: 0;
      padding: 0;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  .comment__author {
    font-weight: bold;
    color: #191919;
    font-size: 16px;
  }

  .comment__submitted {
    margin: 0;
    padding: 0;
  }

  .comment__time {
    color: #b4b4b4;
    font-size: 14px;
    padding: 0 20px;
    font-weight: 300;
  }

  footer.comment__meta {
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    border: 0;
  }
}

@media (max-width: 840px) {
  .comment-wrapper {
    .title {
      margin-bottom: 0;
    }

    .links.inline {
      position: static;
    }

    .indented {
      width: calc(100% - 30px);
      margin-left: 30px;
    }
  }
}
