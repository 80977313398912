.cart--cart-block {
  clear: both;
  text-align: center;
  padding-bottom: 20px;

  a.button-link {
    background: none;
    display: block;
    text-align: right;
    max-width: max-content;
    margin-right: 0;
  }
}
