.region-breadcrumb {
  position: relative;
  min-height: 128px;

  .block {
    &.block-system-breadcrumb-block {
      display: inline-block;
      text-align: center;
      width: 100%;
    }

    .breadcrumb {
      padding: 20px 10px;
      background-color: rgba(136, 209, 206, 0.9);
      box-sizing: border-box;
      display: block;
      color: #fff;

      li {
        text-transform: uppercase;
        pointer-events: none;
        font-weight: 600;
        font-size: 0.750em;

        &:last-child {
          display: block;
          margin-top: 20px;
          font-weight: 600;
          font-size: 1.5em;
          color: #fff;

          &:before {
            display: none;
          }

          a {
            color: #fff;
            margin: 0;
          }
        }

        &:first-child:last-child {
          margin-top: 0;
        }

        a {
          color: #fff;
          text-decoration: none;
          pointer-events: none;
          cursor: default;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .region-breadcrumb {
    .block {
      &.block-system-breadcrumb-block {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;

        &#block-breadcrumbs-2 {
          bottom: auto;
          top: 4vw;
        }
      }

      .breadcrumb {
        display: inline-block;
        padding: 30px 100px;

        li:last-child {
          font-size: 2em;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .region-breadcrumb {
    .block {
      .breadcrumb {
        padding: 30px 190px;
      }
    }
  }
}
