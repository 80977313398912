.region-front-galleries {
  .toGallery {
    float: none;
    clear: both;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 40px;

    a {
      display: inline-block;
      padding: 15px 0;
      width: 215px;
      text-transform: uppercase;
      margin: 0;
      letter-spacing: 1px;
      background-color: #88d1ce;
      color: #fff;
      text-decoration: none;
      transition: 0.3s background-color ease;
      font-weight: 300;

      &:hover {
        background-color: #004f51;
      }
    }
  }
}
