.region-cenradis-wrapper {
  text-align: center;
  display: flex;

  .region-cenradis {
    background: color(dark-green);
    width: 880px;
    margin: 0 auto;
    max-width: 100%;
    display: inline-block;
    text-align: left;
    // overflow: hidden;

    @media (max-width: 980px - 1) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
