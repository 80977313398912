.layout-content-bottom {
  box-sizing: border-box;
  background-color: #f6f5f5;

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.250em;
    margin: 35px 0;
    color: #b25d5d;
  }
}
