header {
  .search-api-page-block-form,
  .block-views-exposed-filter-blocksearch-search-api-page-block-form {
    vertical-align: middle;
    width: 30px;
    height: 42px;
    display: inline-block;
    position: static !important;

    .block-bior-theme-search {
      position: absolute;
      width: 26px;
      top: 50%;
      transform: translateY(-50%);
      right: auto;
      z-index: 15;
      transition: width 0.2s ease, right 0.2s ease;
      background: #fff;
      background-image: none !important;
      display: inline-block;
      text-align: center;

      .closeIcon {
        display: none;
        float: left;
        background: url('../images/bior-icons-search-close.svg') no-repeat center center;
        width: 26px;
        height: 26px;
        cursor: pointer;
      }

      form {
        width: 100%;
        display: table;
        margin: 0;

        .form-item {
          display: none;
          margin: 0;
          width: 100%;

          .form-text {
            height: 35px;
            width: 100%;
            border-width: 0 0 2px 0;
            border-color: #006265;
            outline: none;
            margin-left: 0;
            box-sizing: border-box;
            background: #fff;
          }
        }

        .form-actions {
          display: table-cell;
          width: 26px;
          background: url('../images/bior-search-icon.svg') no-repeat center center;
          cursor: pointer;

          .form-submit {
            outline: none;
            border: none;
            width: 26px;
            height: 26px;
            background: transparent;
            color: transparent;
          }
        }
      }

      &.show {
        width: 340px;
        right: 0;

        form {
          height: 40px;
        }

        .closeIcon {
          display: block;
          position: absolute;
          margin: 0;
          left: 0;
        }

        .form-item {
          display: block;

          .form-text {
            padding: 0 30px 5px 30px;
          }
        }

        .form-actions {
          position: absolute;
          margin: 0;
          right: 0;
        }
      }
    }
  }
}

.search-api-autocomplete-search {
  .ui-menu-item-wrapper {
    display: block;

    .search-api-autocomplete-suggestion {
      max-width: 320px;
      position: relative;
      list-style: none;
      cursor: pointer;
      overflow: hidden;
      margin: 10px 0;
      padding: 10px;
      font-size: 14px;
      line-height: 1.2;
      background: none;
      text-align: left;
    }

    &:hover,
    &.ui-state-active {
      background-color: #88d1ce;
      color: #fff;
      border: none;
      outline: none;

      .search-api-autocomplete-suggestion {
        background-color: #88d1ce;
      }
    }
  }
}

@media (max-width: 1220px) {
  header {
    .search-api-page-block-form,
    .block-views-exposed-filter-blocksearch-search-api-page-block-form {
      .block-bior-theme-search {
        top: auto;
        transform: none;
        right: auto;
        margin-top: 7px;
        margin-left: -7px;

        &.show {
          width: 100%;
        }
      }
    }
  }
}
