.block {
  &#block-totop {
    text-align: center;
    margin-top: 10px;

    #toTop {
      display: inline-block;
      width: 44px;
      height: 44px;
      text-indent: 0;
      cursor: pointer;
      background: #88d1ce url('../images/bior-icon-arrow-top-white.svg') no-repeat center center;
      background-size: 16px;
      transition: background-color 0.5s ease;

      &:hover {
        background-color: #004f52;
      }

      svg {
        display: block;
      }
    }
  }
}
