.block-bior-share-links,
.block-social-sharing-block {
  margin: 20px auto 0 auto;
  max-width: 740px;
  padding: 0 10px;
  box-sizing: border-box;

  .social-media-sharing {
    li {
      margin-right: 5px;
      opacity: 1;
      transition: opacity;
      display: inline-block;
      text-decoration: none;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
