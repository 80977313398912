body.path-search {
  main {
    .block-bior-theme-search {
      text-align: center;
      .search-api-page-block-form {
        display: inline-block;
        text-align: center;

        .form-item {
          min-width: 320px;
          display: inline-block;

          .form-text {
            width: 100%;
            outline: none;
            box-shadow: none;
            border-top: 1px solid #004f51;
            border-bottom: 1px solid #004f51;
            border-left: 0;
            border-right: 0;
            padding: 10px 43px 10px 20px;

            &:focus {
              outline: none;
            }
          }
        }

        .form-actions {
          vertical-align: middle;
          margin: 0 25px;
          display: inline-block;
          width: 26px;
          background: url('../images/bior-search-icon.svg') no-repeat center center;
          cursor: pointer;

          .form-submit {
            outline: none;
            border: none;
            width: 26px;
            height: 26px;
            background: transparent;
          }
        }
      }
    }

    .search-results {
      padding: 20px 0;
      background: #f6f5f5;
      overflow: hidden;

      li {
        line-height: 1.5;
        margin: 10px 0;
        padding: 0 20px;

        h3 {
          font-size: 1em;
          font-weight: 700;
          margin: 0 auto;
          padding: 0;

          a {
            text-decoration: none;
          }
        }

        p {
          margin: 0 0 5px 0;
          padding: 0;
        }
      }

      .search-result__snippet-info {
        border-bottom: 1px solid #88d1ce;
        padding-bottom: 10px;
      }
    }
  }
}
