.block {
  &.block-views-blockpakalpojumi-block-1 {
    margin: 0 auto;
    padding: 0;
    max-width: 740px;

    .view-filters {
      display: none;
    }

    .views-row {
      padding: 0 0 10px 0;
      overflow: hidden;
      border-bottom: 2px solid #88d1ce;
      position: relative;

      .views-field-title {
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        position: relative;
        transition: font-weight 0.3s;
        padding: 20px 20px 10px 45px;

        &:before {
          position: absolute;
          content: '';
          width: 14px;
          height: 2px;
          background: #191919;
          top: 28px;
          left: 20px;
          transition: background-color 0.3s, transform 0.5s;
        }

        &:after {
          position: absolute;
          content: '';
          width: 2px;
          height: 14px;
          background: #191919;
          top: 22px;
          left: 26px;
          transition: background-color 0.3s, transform 0.5s;
        }
      }

      .views-field-nothing {
        line-height: 1.33;
        font-size: 14px;
        font-weight: 300;
        height: 0;
        overflow: hidden;
        transition: 0.3s height;
        padding: 0 20px 0 45px;

        & > div {
          display: table;
          width: 100%;
        }

        p {
          margin: 10px 0 0 0;
        }

        strong {
          font-weight: 600;
        }

        table {
          border: none;
          width: 100%;

          tr {
            border-bottom: 1px solid #b25d5b;

            &:last-child {
              border-bottom: none;
            }

            td {
              border: none;
              vertical-align: top;
              padding-right: 20px;

              &:nth-child(even) {
                border-left: 2px solid #b25d5b;
                padding-left: 20px;
                padding-right: 0;
                width: 150px;
              }
            }
          }
        }
      }

      .redLine {
        padding-left: 20px;
        border-left: 2px solid #b25d5b;
      }

      .cena {
        width: 180px;
        vertical-align: top;
      }

      &.open {
        .views-field-title {
          font-weight: 600;

          &:before,
          &:after {
            background-color: #b25d5b;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
        }
      }
    }
  }
}
