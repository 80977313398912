body {
  background: #fff;
  color: color(font-color);
  font-family: $default-font-family;
  font-size: 16px;
  line-height: 1;
  min-height: 100vh;

  * {
    box-sizing: border-box;
  }

  &.page-node-type-galerija {
    overflow: hidden;
  }

  &.hide-overflow {
    overflow: hidden;
  }
}

ul {
  li {
    list-style-image: url('../images/bior-bullet-red.svg');
  }
}


img, audio, canvas, progress, video {
  max-width: 100%;
  height: auto;
}


a {
  color: color(url-color);
  text-decoration: underline;
  @include transition(all, 0.3s, ease);

  &.redFull {
    display: block;
    padding: 10px 60px 10px 40px;
    text-align: left;
    color: #fff;
    background: #b25d5d url('../images/bior-icon-arrow-right-white.svg') no-repeat 98% center;
    background-size: 40px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: 1.3;
    transition: background-position-x 0.3s;

    &:before {
      display: none !important;
    }
  }

  &:hover {
    color: color(url-hover);

    &.redFull {
      color: #fff;
      background-position-x: 99%;
    }
  }
}
