.menu--main {
  &.menu {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 30px;
    box-sizing: border-box;
  }

  .menu__back {
    position: absolute;
    z-index: 100;
    top: 38px;
    font-size: 0.750em;
    left: 75px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: #fff;
    border: none;
    background: none;

    &:before {
      content: '';
      position: absolute;
      border-left: 1px solid #fff;
      left: -10px;
      top: 0;
      width: 1px;
      height: 100%;
    }

    &.menu__back--hidden {
      pointer-events: none;
      opacity: 0;
    }

    &:hover,
    &:focus {
      color: #fff;
      outline: none;
    }
  }

  .menu__breadcrumbs {
    border-bottom: 1px solid #006f72;
    line-height: 1.3;
    position: relative;
    padding: 5px 0;
    font-size: 0.750em;

    a {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;
      letter-spacing: 1px;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;

      &:not(:last-child)::after {
        content: '\00bb';
        display: inline-block;
        padding: 0 0.5em;
        font-weight: 600;
        color: #fff;
      }
    }
  }

  .menu__wrap {
    position: absolute;
    top: 80px;
    bottom: 0;
    width: 100%;
    height: calc(100% - 80px);

    .jspTrack {
      background: #006f72;
    }

    a {
      position: relative;
      display: block;
      padding: 7px 0;
      color: #88d1ce;
      font-weight: 300;
      font-size: 1.125em;
      line-height: 1.33;
      text-decoration: none;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;

      &[data-submenu]::after {
        content: '';
        font-weight: 800;
        position: absolute;
        right: 0;
        top: 20%;
        width: 8px;
        height: 13px;
        background: url('../images/bior-menu-arrow.svg') no-repeat;
      }

      &:hover {
        color: #fff;
      }

      &[data-submenu]:hover::after {
        color: #209a95;
      }
    }

    .menu__level {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;

      overflow: hidden;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      list-style-type: none;

      &.menu__level--current {
        visibility: visible;
      }
    }

    .menu__item {
      display: block;
      width: 95%;
    }
  }

  .animate-outToRight .menu__item {
    -webkit-animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  }

  .animate-outToLeft .menu__item {
    -webkit-animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  }

  .animate-inFromLeft .menu__item {
    -webkit-animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  }

  .animate-inFromRight .menu__item {
    -webkit-animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
    animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
  }
}


html[lang="lv"],
html[lang="en"],
html[lang="ru"],
html[lang="ru"] {
  .menu--main {
    .menu__wrap {
      a.is-active:not([href="/lv"]),
      a.is-active:not([href="/en"]),
      a.menu__link--current:not([href="/ru"]) {
        color: #fff;
        font-weight: 300;
        font-size: 1.125em;
        line-height: 1.33;
      }
    }
  }
}

@-webkit-keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes outToRight {
  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@-webkit-keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes outToLeft {
  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes inFromRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
