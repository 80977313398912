.jspTrack {
  background: #dde;
  position: relative;
}

.jspDrag {
  background: #88d1ce;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.jspVerticalBar {
  width: 2px;
}
