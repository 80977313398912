.block {
  &.block-views-blockiepirkumi-raksti-block-1 {
    .view-header {
      text-align: center;
    }

    .view-content {
      display: none;
    }
  }
}
