.banner-section {
  padding: 0;
  margin: 20px 0;

  .region-front-banners {
    border-top: 2px solid color(light-blue);
    border-bottom: 2px solid color(light-blue);

    &:after {
      content: "";
      display: table;
      clear: both;
    }

    .block {
      .view-banneri- {
        width: 100%;
        margin: 10px 0 10px 0;

        .view-content {
          overflow: hidden;
          max-height: 300px;

          .views-row {
            height: 300px;
            display: table;

            img {
              max-width: 100%;
              height: auto;
            }

            .views-field-field-banneris-attels {
              display: table-row;

              .field-content {
                display: table-cell;
                width: 100%;
                vertical-align: middle;
                text-align: center;
              }

              a {
                text-align: center;
                display: inline-block;
              }
            }
          }
        }

        .slick-slider {
          padding: 0 50px;
        }

        .slick-slide {
          margin: 0 20px;
          outline: none;
        }

        .slick-prev,
        .slick-next {
          width: 15px;
          height: 24px;

          &:before {
            display: none;
          }
        }

        .slick-prev {
          left: 10px;
          background: url('../images/bior-baner-left.png');
        }

        .slick-next {
          right: 10px;
          background: url('../images/bior-baner-right.png');
        }
      }

      &#block-vienslogo {
        text-align: center;

        a:before {
          display: none;
        }
      }
    }

  }
}

@media (min-width: 900px) {
  .banner-section {
    padding: 0;
    margin: 0;
  }
}

@media (min-width: 1000px) {
  .banner-section {
    .region-front-banners {
      .block {
        .view-banneri- {
          margin: 0;
          float: left;
          width: calc(100% - 420px);
        }

        &#block-vienslogo {
          float: right;
          width: 420px;
          display: table;

          .field--name-body {
            display: table-row;

            a {
              display: table-cell;
              height: 300px;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
