.block {
  &.block-views-blockvakances-block-1 {
    box-sizing: border-box;
    padding: 0 10px;
    max-width: 740px;
    margin: 0 auto;
    position: relative;
    line-height: 2em;

    .item-list {
      ul {
        li {
          list-style-image: url('../images/bior-bullet-red.svg');
          position: relative;
          margin: 3px 0;
          padding-left: 10px;

          li {
            list-style-image: url('../images/bior-bullet-blue.svg');
          }

          &:last-child {
            border: none;
          }
        }
      }

      li {
        margin: 15px 0;
        font-size: 1.5em;
        font-weight: 500;
        padding-bottom: 10px;

        a {
          color: #b25d5b;
          text-decoration: none;
        }
      }
    }
  }
}
