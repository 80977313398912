.region-cenradis-wrapper,
.view.view-cenradis {
  .views-exposed-form {
    overflow: hidden;
    background: color(dark-green);
    width: 880px;
    max-width: 100%;
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    clear: both;

    @media (min-width: 980px) {
      padding: 20px;
    }

    @media (max-width: 980px) {
      &.block-views-exposed-filter-blockcenradis-views-cenradis-search-1 {
        padding: 20px 0;

        input[name="search"] {
          font-size: 12px
        }
      }
    }

    .form--inline {
      position: relative;
    }

    .form-submit {
      position: absolute;
      top: 0;
      left: 0;
      width: 44px;
      height: 50px;
      font-size: 0;
      outline: none;
      background: url('/modules/custom/bior_custom/images/bior-icon-cenas-search.svg') no-repeat center center;
      background-color: transparent;
      box-shadow: none;
      border: none;
    }

    input[name="search"] {
      height: 50px;
      line-height: 50px;
      width: 100%;
      outline: none;
      padding: 0 20px 0 50px;
      border: none;
      box-shadow: none;
    }

    .form-item {
      width: 100%;
      margin: 0;
    }
  }
}
