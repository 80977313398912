article.node--type-iepirkumi {
  .field--name-body,
  .node__content {
    max-width: 100%;
  }

  .field--name-body {
    table {
      width: 100%;
      display: block;
      overflow: auto;
      font-size: 14px;

      thead {
        color: #313131;
        background: #fff;

        td, th {
          font-weight: 600;
          font-size: 1em;
          border-bottom: 3px solid rgb(136, 209, 206);
          text-align: left;
          text-transform: none;
          line-height: 1.2;
          padding: 11px 20px 11px 5px;

          b, strong {
            font-weight: 600;
          }
        }
      }

      tbody {
        tr {
          background: #fff;
          transition: background 0.3s ease;

          td {
            padding: 8px 20px 8px 5px;
            font-weight: 400;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: transparent transparent rgb(136, 209, 206) transparent;
          }
        }
      }
    }
  }
}
