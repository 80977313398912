body.page-node-type-article {
  main {
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
  }
}


@media (min-width: 900px) {
  body.page-node-type-article {
    main {
      padding: 0 50px;
    }

    .layout-content {
      box-sizing: border-box;
      width: calc(100% - 350px);
      float: left;
    }
  }
}
