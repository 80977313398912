.item-list__checkbox {
  .block-facet-blockdzivnieka-veids &, .block-facet-blockslimibas & {
    z-index: 1;
    cursor: pointer;
    padding-top: 50px;
    padding-left: 0;
    max-height: 50px;
    overflow: hidden;
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    margin: 0;

    &:after {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      display: inline-block;
      content: "";
      background: url("/modules/custom/bior_custom/images/bior-icon-cenas-down.svg") no-repeat center right 15px;
      width: calc(100% - 50px);

      @media (min-width: 980px) {
        left: 50px;
        width: 360px;
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    .facet-item {
      list-style-type: none;
      list-style-image: none;
      background: color(white);

      &:last-child {
        label {
          border-bottom: none;
        }
      }
    }

    a.is-active {
      display: block !important;
      width: 100%;
      float: left;
      overflow: hidden;
      margin-top: -50px;
      padding: 0 40px 0 15px;
      text-decoration: none;

      &:before {
        display: inline-block;
        background: color(white);
        color: color(brown);
        font-size: 18px;
        position: absolute;
        top: 0;
        left: 0;
        content: "\2716";
        z-index: 2;
        width: 30px;
        height: 50px;
        text-align: center;
        padding: 0;
        line-height: 50px;
      }
    }

    label {
      display: block;
      border-bottom: 1px solid #e6e7e8;
      cursor: pointer;
    }

    &.dropdown-visible {
      padding-top: 0;
      max-height: 100vh;
      overflow-y: scroll;
      z-index: 2;
      box-shadow: 0px 0px 2px #ddd;

      a.is-active {
        display: none !important;
      }

      > li:first-child {
        padding-top: 50px;
      }

      label {
        padding: 13px 10px;
      }

      &:after {
        z-index: 6;
        background: url("/modules/custom/bior_custom/images/bior-icon-cenas-up.svg") no-repeat center right 15px;
      }
    }
  }
}
