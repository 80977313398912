.block {
  .view-banneru-slaideris {

    * {
      box-sizing: border-box;
    }

    .view-content {
      .views-row {
        display: none;

        &:first-child {
          display: block;
        }

        .views-field-field-banner-image {
          z-index: 5;

          &:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+47,0.77+100 */
            background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.77) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.77) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.77) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#c4000000', GradientType=0); /* IE6-9 */

          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .views-field-title {
          z-index: 10;
          font-weight: 400;
          position: absolute;
          bottom: 48px;
          width: 90%;
          left: 5%;
          color: #fff;
          font-size: 20px;
          transition: all 0.3s ease;
          margin: 0;
        }
      }
    }

    .bx-wrapper {
      margin: 0 auto;
      box-shadow: none;
      border: none;

      .bx-viewport {
        border: none;
        box-shadow: none;
        left: 0;

        .view-content {
          .views-row {
            display: block;

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }

      .bx-controls {
        display: none;

        .bx-pager {
          text-align: left;
          position: relative;
          bottom: 0;

          &.bx-default-pager {
            a {
              background: transparent url('../images/pasive_slide.png');
              text-indent: -9999px;
              display: block;
              width: 12px;
              height: 12px;

              &:hover, &:active, &.active {
                background: transparent url('../images/active_slide.png');
              }
            }
          }

          .bx-pager-item {
            margin: 5px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 400px) {
  .block {
    .view-banneru-slaideris {
      .bx-wrapper {
        .bx-controls {
          position: absolute;
          display: block;
          bottom: 20px;
          left: 20px;
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .block {
    .view-banneru-slaideris {
      .view-content {
        .views-row {
          .views-field-title {
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media (min-width: 700px) {
  .block {
    .view-banneru-slaideris {
      .view-content {
        .views-row {
          .views-field-title {
            font-size: 44px;
          }
        }
      }
    }
  }
}

@media (min-width: 800px) {
  .block {
    .view-banneru-slaideris {
      .bx-wrapper {
        .bx-controls {
          position: absolute;
          top: 50%;
          right: 20px;
          width: 20px;
          left: auto;
          margin-top: -80px;
          padding-top: 0;
        }
      }
    }
  }
}
