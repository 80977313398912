main, .region-breadcrumb {
  .block.block-menu.navigation {
    padding-bottom: 35px;
    padding-top: 35px;
    border-bottom: 2px solid color(light-blue);
    border-top: 2px solid color(light-blue);
    margin: 10px 0;

    .menu__wrap {
      a[data-submenu]::after {
        content: '';
        font-weight: 800;
        position: absolute;
        right: 0;
        top: 20%;
        width: 8px;
        height: 13px;
        background: url('../images/bior-menu-arrow.svg') no-repeat;
      }
    }

    .icon {
      font-weight: normal;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      text-transform: none;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      speak: none;
    }

    h2 {
      font-size: 1.250em;
      text-align: center;
      font-weight: 800;
      color: #88d1ce;
      text-transform: uppercase;
    }

    & > ul.menu {
      margin: 0;
      padding: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 10px 5px;

        &:after {
          display: inline-block;
          content: " ";
          width: 4px;
          height: 4px;
          border-radius: 2px;
          vertical-align: middle;
          margin-left: 5px;
          background-color: #88d1ce;
        }

        a {

          text-decoration: none;
          font-size: 1.125em;
          color: #191919;
          transition: color 0.3s ease;

          &:hover {
            color: #b9b9b9;
          }
        }

        /*&.menu-unpublished,*/
        &.group-lock{
          a {
            display: inline-block;
            position: relative;
            padding-right: 20px;

            &:after {
              display: block;
              content: '';
              position: absolute;
              right: 0;
              top: 0;
              width: 16px;
              height: 16px;
              background-color: #fff;
              background-repeat: no-repeat;
            }
          }
        }

        &.group-lock a:after {
          background-image: url('../images/atsledzina-01.svg');
        }

        /*&.menu-unpublished a:after {
          background-image: url('../images/bior_acs-02.svg');
        }*/

        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    li {
      display: block;
      margin: 10px 0;
      padding: 0;
      position: relative;

      .menu-item--active-trail {
        font-weight: 700;
      }
    }
  }
}
