.block-user-login-block {
  text-align: center;

  .user-login-form-visibility {
    h2 {
      display: none;
    }

    .user-login-form-wrapper {
      display: block;
    }

    &.show {
      h2 {
        display: inline-block;
      }

      .user-login-form-wrapper {
        display: none;
      }
    }
  }

  h2 {
    padding: 15px 40px;
    background: color(light-blue);
    color: #FFF !important;
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 600;
    cursor: pointer;
  }

  .user-login-form-wrapper {
    padding: 15% 5%;
    width: 100%;
    float: left;
    background: rgb(136, 209, 206);
    clear: both;
    text-align: left;

    form {
      padding: 0;
      background: transparent;

      * {
        box-sizing: border-box;
      }
    }

    .item-list {
      float: left;
      width: 100%;
      margin: 5px 0;

      a {
        color: #fff;
      }
    }
  }
}

.user-login-form {
  float: left;
  width: 100%;
  display: table;
  box-sizing: border-box;
  padding: 125px 15% 130px;
  background-color: rgb(136, 209, 206);

  .form-item {
    display: table-cell;
    vertical-align: top;
    box-sizing: border-box;
    padding: 0 15px;
    height: 95px;

    label {
      display: inline-block;
      clear: both;
      width: 100%;
      padding-bottom: 5px;
      color: #fff;
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px;
    outline: none;
    border: none;
    color: #004f51;
    background-color: #fff;
  }

  .form-actions {
    display: table-cell;
    vertical-align: middle;

    input {
      background: #B45C59;
      color: #FFF;
      text-transform: uppercase;
    }
  }
}

@media (max-width: 990px) {
  .block-user-login-block {
    .user-login-form-wrapper {
      form {
        padding: 40px 10%;

        .form-item {
          width: 100%;
          padding: 0;
          float: left;
        }

        .form-actions {
          float: left;
        }
      }
    }
  }
  .user-login-form {
    padding: 40px 10%;

    .form-item {
      width: 100%;
      padding: 0;
      float: left;
    }

    .form-actions {
      float: left;
    }
  }
}
