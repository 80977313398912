.view-jaunumi {
  .views-exposed-form {
    .form--inline {
      & > .form-item {
        .form-select {
          border-bottom: none;
          width: auto;
          background: none;

          div:after {
            top: 2px
          }
        }
      }
    }
  }

  .views-row {
    box-sizing: border-box;
    padding: 0;
    position: relative;
    border: 2px solid #88d1ce;
    margin-bottom: 20px;

    .toLists {
      background: url('../images/bior-event-icon.svg');
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      text-indent: -999px;
      overflow: hidden;
      background-color: rgb(255, 255, 255);

      span {
        background: #68cef2;
      }

      svg {
        position: absolute;
        display: block !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: visible;

        polyline {
          stroke-dasharray: 500;
          stroke-width: 4px;
          stroke: #88d1ce;
          -webkit-transition: stroke-dashoffset 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
          -moz-transition: stroke-dashoffset 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
          -ms-transition: stroke-dashoffset 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
          -o-transition: stroke-dashoffset 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
          transition: stroke-dashoffset 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);

          &.path1 {
            stroke-dashoffset: 500;
          }

          &.path2 {
            stroke-dashoffset: -500;
          }
        }
      }

      .left {
        position: absolute;
        width: 2px;
        height: 0;
        top: 0;
        left: 0;
        webkit-transition: height 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        -moz-transition: height 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        -ms-transition: height 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        -o-transition: height 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        transition: height 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
      }

      .bottom {
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        right: 0;
        webkit-transition: width 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        -moz-transition: width 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        -ms-transition: width 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        -o-transition: width 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
        transition: width 0.45s cubic-bezier(0.55, 0.085, 0, 0.99);
      }
    }

    &.type-notikums {
      border: 2px solid #68cef2;

      .toLists {
        svg polyline {
          stroke: #68cef2;
        }
      }

      .link {
        a {
          background-image: url('../images/bior-icon-arrow-right-blue.svg');
        }
      }
    }

    &.type-article {
      .toLists {
        background: url('../images/bior-news-icon.svg');

        span {
          background: #88d1ce;
        }
      }

      .type {
        color: #bebebe;
      }
    }

    &:hover {
      .toLists {
        .left {
          height: 76px;
        }

        .bottom {
          width: 76px;
        }

        svg {
          polyline {
            &.path1 {
              stroke-dashoffset: 0;
            }

            &.path2 {
              stroke-dashoffset: -1000;
            }
          }
        }
      }
    }
  }

  .title {
    margin: 20px 0;
    font-size: 1.4em;
    font-weight: 300;

    a {
      color: #191919;
      text-decoration: none;
    }
  }

  .content {
    line-height: 1.3;
    margin-bottom: 20px;
    font-weight: 300;
  }

  .link {
    a {
      display: inline-block;
      text-indent: -99999px;
      width: 15px;
      height: 20px;
      /*background: url('../images/bior-read-more.png') no-repeat center right;*/
      background: url('../images/bior-icon-arrow-right-green.svg') no-repeat center right;
      background-size: 54px 11px;
      transition: width 0.3s ease;

      &:hover {
        width: 35px;
      }
    }
  }

  .vieta {
    position: relative;
    margin-right: 25px;
    display: inline-block;

    &:before {
      display: inline-block;
      content: ' ';
      width: 16px;
      height: 16px;
      margin-right: 5px;
      vertical-align: middle;
      background: url('../images/bior-vieta-icon.svg');
    }
  }

  .laiks {
    display: inline-block;

    &:before {
      display: inline-block;
      content: ' ';
      width: 16px;
      height: 16px;
      margin-right: 10px;
      vertical-align: middle;
      background: url('../images/bior-clock-icon.svg');
    }
  }

  .linkButton {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    font-weight: 300;
    text-align: center;
    width: 140px;
    color: #191919;
    letter-spacing: 1px;
    line-height: 34px;
    margin-bottom: 10px;
    font-size: 14px;
    border: 1px solid #68cef2;
    background: #fff;
    transition: background 0.3s, color 0.3s;

    &:hover {
      background: #68cef2;
      color: #fff;
    }

    &.inside {
      display: inline-block;
      text-transform: uppercase;
      text-decoration: none;
      position: relative;
      font-weight: 300;
      text-align: center;
      width: 140px;
      letter-spacing: 1px;
      line-height: 34px;
      margin-bottom: 10px;
      font-size: 14px;
      border: 1px solid #68cef2;
      transition: background 0.3s, color 0.3s;
      color: #fff;
      background: #68cef2;

      &:hover {
        background: #fff;
        color: #68cef2;
      }
    }

  }

  .type {
    font-size: 0.875em;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-right: 40px;
    font-weight: 300;
    line-height: 1.7em;

    a {
      color: #bebebe;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #313131;
      }
    }

    .seperator {
      position: relative;
      display: inline-block;
      width: 0;
      margin: 0 10px;

      &:before {
        position: absolute;
        content: " ";
        width: 1px;
        height: 20px;
        left: 0;
        background-color: #bebebe;
        bottom: -5px;
      }
    }
  }

  .view-footer {
    text-align: center;
    margin: 20px 0 60px 0;
    padding: 0;

    a {
      display: inline-block;
      padding: 15px 0;
      width: 215px;
      text-transform: uppercase;
      margin: 0;
      letter-spacing: 1px;
      background-color: #88d1ce;
      color: #fff;
      text-decoration: none;
      transition: 0.3s background-color ease;
      font-weight: 300;

      &:hover {
        background-color: #004f51;
      }
    }
  }
}

@media (min-width: 800px) {
  .view-jaunumi {
    .views-row {
      padding: 15px 90px 15px 20px;

      .toLists {
        width: 76px;
        height: 76px;
        background-color: transparent;
      }
    }

    .type {
      padding-right: 0;
    }
  }
}

@media (min-width: 600px) {
  .view-jaunumi {
    .title {
      font-size: 1.750em;
    }
  }
}
