.block {
  &.block-views-blockpublikacijas-block-1 {
    margin: 0 auto;

    table {
      width: 100%;

      th {
        background-color: #89d1cf;
        color: #fff;
        padding: 3px 5px;
        font-weight: 600;
        box-sizing: border-box;
      }

      tr {
        border-bottom: 2px solid #88d1ce;
        transition: background-color 0.3s ease;
        padding: 5px;

        &:hover {
          background-color: #ade2e0;
        }

        td {
          padding: 12px 5px;
          box-sizing: border-box;
          line-height: 1.3;
        }
      }
    }

    .views-field-field-publikacijas-datums,
    .views-field-nothing,
    .views-field-field-publikacija-datu-baze {
      text-align: center;
      width: 50px;
    }

    .views-field-field-publikacija-datu-baze {
      span {
        display: inline-block;
        background: url('../images/bior-checked-icon.svg') no-repeat center center;
        text-indent: -9999px;
        width: 22px;
        height: 22px;
        vertical-align: top;
      }
    }

    .views-field-nothing {
      div {
        a {
          display: inline-block;
          background: url('../images/icon-link.svg') no-repeat center center;
          text-indent: -9999px;
          width: 30px;
          height: 30px;
        }
      }

      a[href$=".pdf"] {
        background: url('../images/icon-pdf.svg') no-repeat;
      }

    }
  }
}
