.block {
  &#block-styleswitcher {
    .style-switcher-button {
      outline: 0;
      text-align: center;
      background: transparent;
      border: 0;
      display: flex;
      align-items: stretch;
      flex-direction: row;
      flex-flow: initial;

      &__eye {
        display: flex;
        align-items: center;
        margin-right: 10px;

        svg {
          width: 35px;
          height: 30px;
          fill: #b3bfc5;

          @media (max-width: 900px) {
            width: 25px;
            height: 20px;
          }
        }
      }

      &__arrow {
        display: flex;
        align-items: center;

        svg {
          width: 15px;
          height: 30px;
          fill: #b3bfc5;

          @media (max-width: 900px) {
            width: 10px;
            height: 25px;
          }
        }
      }
    }

    @media (max-width: 400px) {
        display: flex;
        width: 50%;
        justify-content: flex-end;
    }

    .style-switcher-popup {
      display: none;
      z-index: 16;
      position: absolute;
      background: #FFF;
      top: 100%;
      padding: 26px;
      border: 0.5px solid #B3BFC5;
      left: 0;
      transform: translateX(0);

      @media (min-width: 1220px) {
        left: calc(50% + 26px);
        transform: translateX(calc(-50% - 26px));
      }

      ul {
        list-style: none;
        margin: 0;

        li {
          list-style-image: none;
          display: inline-block;
          text-align: left;
          margin: 0;

          a {
            position: relative;
            display: block;
            min-width: 311px;
            padding: 15px 15px 15px 55px;
            margin-bottom: 10px;
            text-decoration: none;
            font-size: 16px;
            border: 0;

            @media (max-width: 432px) {
              min-width: 250px;
            }

            &:before {
              content: '';
              position: absolute;
              left: 15px;
              width: 25px;
              height: 15px;
              background-repeat: no-repeat;
            }

            &.style-default {
              color: #fff;
              background-color: #006265;

              &:before {
                background-image: url("../images/icon-eye-white.svg");
              }
            }

            &.style-balck-on-white {
              color: #000;
              background-color: #fff;

              &:before {
                background-image: url("../images/icon-eye-black.svg");
              }
            }

            &.style-black-on-yellow {
              color: #000;
              background-color: #fff86e;

              &:before {
                background-image: url("../images/icon-eye-white.svg");
              }
            }

            &.style-white-on-black {
              color: #fff;
              background-color: #000;

              &:before {
                background-image: url("../images/icon-eye-white.svg");
              }
            }

            &.style-yellow-on-black {
              color: #fff86e;
              background-color: #000;

              &:before {
                background-image: url("../images/icon-eye-white.svg");
              }
            }
          }

          &:last-child {
            a {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &.open {

      .style-switcher-popup {
        display: block;
      }

      .style-switcher-button {
        &__arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
}
