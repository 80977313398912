body.page-node-type-galerija {
  article {
    .node--type-galerija {
      .node__content {
        img {
          width: 100%;
          height: auto;
        }

        .field__item {
          float: left;
          width: 33.33%;
          box-sizing: border-box;
          padding: 3px;
          overflow: hidden;
        }
      }
    }
  }

  #imageGallery {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 999;

    .galleryTitle {
      font-size: 1.5em;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .col1 {
      background: #004f52;
      text-align: center;
      position: relative;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .col2 {
      width: 320px;
      box-sizing: border-box;
      padding: 75px 15px 10px 25px;
      overflow-y: auto;
      overflow-x: hidden;
      vertical-align: top;
      display: none;

      div.content {
        overflow: hidden;
        overflow-y: auto;
        height: 100%;
        width: 280px;
      }
    }

    .gallPrev,
    .gallNext {
      position: absolute;
      top: 0;
      z-index: 2;
      display: inline-block;
      width: 50%;
      height: 100%;
      background: transparent url('../images/bior-icon-arrow-right-white.svg') no-repeat calc(100% - 20px) center;
      background-size: 42px;
      cursor: pointer;
      transition: background-position 0.3s ease;
      text-indent: -9999px;
      overflow: hidden;

      &:hover {
        background-position: calc(100% - 35px) center;
      }
    }

    .gallPrev {
      left: 0;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    .gallNext {
      right: 0;
    }

    .closeGallery {
      position: fixed;
      display: inline-block;
      text-indent: -9999px;
      width: 40px;
      height: 40px;
      top: 20px;
      right: 20px;
      z-index: 2;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.6) url('../images/bior-icons-galerija-close.svg') no-repeat center center;
      background-size: 25px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

@media (min-width: 900px) {
  body.page-node-type-galerija {
    #imageGallery {
      .col2 {
        display: table-cell;
      }
    }
  }
}
