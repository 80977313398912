.block {
  &.block-views-blocksakums-galeriju-bloki-block-1,
  &.block-views-blocksakums-galeriju-bloki-block-2,
  &.block-views-blockviens-galerijas-video-block-1 {
    .view-content {
      .views-row {
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;

        &:last-child {
          margin-right: 0;
          box-sizing: border-box;
        }

        .views-field-field-galerija-atteli,
        .views-field-field-video {
          line-height: 0;

          a {
            display: block;
            line-height: 0;
            overflow: hidden;
          }

          img {
            width: 100%;
            height: auto;
            transform: translateZ(0);
            -webkit-filter: brightness(80%);
            filter: brightness(80%);
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -ms-transition: all 1s ease;
            -o-transition: all 1s ease;
            transition: all 1s ease;
          }
        }

        .views-field-title {
          position: absolute;
          width: 100%;
          box-sizing: border-box;
          padding: 0 20px;
          text-align: center;
          line-height: 1.875em;
          bottom: 80px;

          a {
            color: #fff;
            text-decoration: none;
            font-size: 1.750em;
            font-weight: 300;
          }
        }

        .views-field-view-node {
          position: absolute;
          bottom: 35px;
          text-align: center;
          width: 100%;

          a {
            color: #fff;
            position: relative;
            text-decoration: none;
            font-size: 0.875em;
            font-weight: 600;
            padding-right: 15px;

            &:after {
              position: absolute;
              top: 0;
              display: inline-block;
              width: 15px;
              content: " ";
              left: 100%;
              height: 19px;
              background: url("../images/bior-view-gallery-white.png") no-repeat center right;
              transition: width 0.3s ease;
            }

            &:hover:after {
              width: 35px;
            }
          }
        }

        &.type-video-galerija {
          &:after {
            background: #fff url('../images/bior-icons-video.svg') no-repeat center center;
          }
        }

        &:after {
          position: absolute;
          top: 0;
          right: 0;
          content: " ";
          display: block;
          width: 80px;
          height: 80px;
          background: #fff url('../images/bior-icons-photo.svg') no-repeat center center;
        }

        &:hover {
          .views-field-field-galerija-atteli,
          .views-field-field-video {
            img {
              -webkit-transform: scale(1.25);
              -moz-transform: scale(1.25);
              -ms-transform: scale(1.25);
              -o-transform: scale(1.25);
              transform: scale(1.25);
            }
          }
        }
      }
    }

    .view-footer {
      text-align: center;
      margin: 20px 0 60px 0;
      clear: both;
      float: none;
      padding: 0;

      a {
        display: inline-block;
        padding: 15px 0;
        width: 215px;
        text-transform: uppercase;
        margin: 0;
        background-color: #88d1ce;
        color: #fff;
        text-decoration: none;
        transition: 0.3s background-color ease;
        font-weight: 600;
      }
    }
  }
}

@media (min-width: 600px) {
  .block {
    &.block-views-blocksakums-galeriju-bloki-block-1,
    &.block-views-blocksakums-galeriju-bloki-block-2,
    &.block-views-blockviens-galerijas-video-block-1 {
      .view-content {
        .views-row {
          float: left;
          width: 49%;
          margin: 0 0.5% 10px 0.5%;

          &:nth-child(2n) {
            margin-right: 0;
          }

          .views-field-title {
            bottom: 60px;
            line-height: 1.4em;

            a {
              font-size: 1.3em;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .block {
    &.block-views-blocksakums-galeriju-bloki-block-1,
    &.block-views-blocksakums-galeriju-bloki-block-2,
    &.block-views-blockviens-galerijas-video-block-1 {
      .view-content {
        .views-row {
          float: left;
          width: 32%;
          margin: 0 2% 10px 0;

          &:nth-child(2n) {
            margin-right: 2%;
          }

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .block {
    &.block-views-blocksakums-galeriju-bloki-block-1,
    &.block-views-blocksakums-galeriju-bloki-block-2,
    &.block-views-blockviens-galerijas-video-block-1 {
      .view-content {
        .views-row {
          .views-field-title {
            bottom: 80px;
            line-height: 1.875em;

            a {
              font-size: 1.750em;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
}
