.block {
  &.block-views-blockpetijumi-block-1,
  &.block-views-blockinfrastrukturas-saraksts-block-1 {
    .view-content {
      justify-content: space-between;
      flex-wrap: wrap;
      display: flex;
    }

    .views-row {
      background-color: #88d1ce;
      transition: background-color 0.5s ease;
      position: relative;
      padding: 0 0 100px 0;
      margin-bottom: 20px;

      &:nth-child(odd) {
        margin-right: 2%;
      }

      .views-field-field-kopskata-attels {
        img {
          width: 100%;
          height: auto;
        }
      }

      .views-field-title {
        padding: 0 10px;
        margin-top: 10px;
        font-size: 1em;
        font-weight: 400;
        line-height: 1.3;

        a {
          color: #fff;
          text-decoration: none;
        }
      }

      .views-field-view-node {
        position: absolute;
        bottom: 30px;
        left: 30px;

        a {
          line-height: 44px;
          height: 44px;
          color: #fff;
          display: inline-block;
          text-transform: uppercase;
          text-decoration: none;

          &:before {
            content: '';
            width: 44px;
            height: 44px;
            margin-right: 15px;
            box-sizing: border-box;
            padding: 5px;
            display: inline-block;
            vertical-align: middle;
            border: 2px solid #fff;
            background: url("data:image/svg+xml,%3Csvg width='40' height='40' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cg id='svg_8'%3E%3Cline id='svg_1' fill='none' stroke='%23fff' stroke-width='2' x1='20.1' y1='9' x2='20.1' y2='31.05811'/%3E%3Cline id='svg_2' fill='none' stroke='%23fff' stroke-width='2' x1='20.1' y1='8.97095' x2='20.1' y2='31.02905' transform='rotate%2890 20.100011825561523,19.99999809265137%29 '/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center center no-repeat;
          }
        }
      }

      &:hover {
        background-color: #b25d5d;
      }
    }
  }
}

@media (min-width: 600px) {
  .block {
    &.block-views-blockpetijumi-block-1,
    &.block-views-blockinfrastrukturas-saraksts-block-1 {
      .views-row {
        .views-field-title {
          padding: 0 30px;
          margin-top: 30px;
          font-size: 1.500em;
        }
      }
    }
  }
}


@media (min-width: 900px) {
  .block {
    &.block-views-blockpetijumi-block-1,
    &.block-views-blockinfrastrukturas-saraksts-block-1 {
      .views-row {
        float: left;
        width: 49%;

        .views-field-title {
          padding: 0 10px;
          margin-top: 10px;
          font-size: 1em;
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .block {
    &.block-views-blockpetijumi-block-1,
    &.block-views-blockinfrastrukturas-saraksts-block-1 {
      .views-row {
        .views-field-title {
          padding: 0 30px;
          margin-top: 30px;
          font-size: 1.500em;
        }
      }
    }
  }
}
