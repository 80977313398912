.block {
  &.block-views-blockdoktori-un-doktoranti-block-4,
  &.block-views-blockdoktori-un-doktoranti-block-2 {
    .view-content {
      h3 {
        display: none;
      }
    }

    .views-view-grid {
      &:nth-child(1) {
        margin-bottom: 80px;
      }

      &:nth-child(2) {
        margin-bottom: 50px;
      }
    }

    .views-row {
      text-align: center;

      .views-col {
        position: relative;
        max-width: 513px;
        box-sizing: border-box;
        line-height: 0;
        margin: 0 auto 20px auto;
        float: none;
        display: inline-block;

        .views-field-edit-node {
          position: absolute;
          top: -10px;
          left: 0;
        }

        .row-content {
          position: relative;
          text-align: left;

          img {
            width: 100%;
            height: auto;
            line-height: 0;
          }

          .overlay {
            .info {
              .title {
                font-size: 1.5em;
                font-weight: 400;
                color: #fff;
                transition: color 0.5s ease;
                padding: 0 100px 0 0;
                position: relative;
                word-spacing: 999px;
              }

              .grads {
                font-size: 0.750em;
                color: #fff;
                position: relative;
                font-style: italic;
                transition: color 0.5s ease;
                padding: 0 50% 10px 0;
                overflow: hidden;
                margin: 5px 0;

                &:after {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 2px;
                  background: #88d1ce;
                  content: '';
                  -webkit-transition: -webkit-transform 0.5s;
                  transition: transform 0.5s;
                  -webkit-transform: translate3d(-100%, 0, 0);
                  transform: translate3d(-100%, 0, 0);
                }
              }

              .pap-info {
                opacity: 0;
                padding: 0;
                line-height: 1.3;
                -webkit-transition: -webkit-transform 0.5s;
                transition: transform 0.5s;
                -webkit-transform: translate3d(0, 40px, 0);
                transform: translate3d(0, 40px, 0);

                .nozare {
                  color: #88d1ce;
                  letter-spacing: 2px;
                  text-transform: uppercase;
                  margin-bottom: 10px;
                }
              }
            }

            .moreInfo {
              position: absolute;
              bottom: 20px;
              right: 20px;
              color: #88d1ce;
              height: 45px;
              line-height: 45px;

              .box {
                display: inline-block;
                width: 45px;
                height: 45px;
                border: 2px solid;
                box-sizing: border-box;
                position: relative;

                &:after {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 2px;
                  height: 20px;
                  left: 19px;
                  top: 10px;
                  background-color: #88d1ce;
                  -webkit-transition: -webkit-transform 0.5s;
                  transition: transform 0.5s;
                  -webkit-transform: rotateZ(0);
                  transform: rotateZ(0);
                }

                &:before {
                  position: absolute;
                  content: '';
                  display: block;
                  width: 20px;
                  height: 2px;
                  left: 10px;
                  top: 19px;
                  background-color: #88d1ce;
                }
              }
            }

            .toProfile {
              position: relative;
              text-decoration: none;
              display: none;
              margin-top: 10px;

              &:after {
                display: inline-block;
                content: '';
                width: 40px;
                height: 10px;
                margin-left: 10px;
                transition: background-position-x 0.3s ease;
                background: url('../images/bior-icon-arrow-right-green.svg') no-repeat 120% center;
              }

              &:hover:after {
                background-position-x: 100%;
              }
            }
          }
        }

        &:hover {
          .row-content {
            .overlay {
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
        }

        &.open {
          .row-content {
            .overlay {
              background-color: rgba(255, 255, 255, 1);

              .info {
                .title {
                  color: #191919;
                  padding: 0 50px 0 0;
                  word-spacing: inherit;
                }

                .grads {
                  color: #191919;

                  &:after {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                  }
                }

                .pap-info {
                  opacity: 1;
                  -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
                }
              }

              .moreInfo {
                .box:after {
                  -webkit-transform: rotateZ(90deg);
                  transform: rotateZ(90deg);
                }
              }
            }
          }
        }
      }
    }
  }

  &.block-views-blockdoktori-un-doktoranti-block-4 {
    margin-top: 40px;

    .views-row {
      .views-col {
        .row-content {
          .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0);
            top: 0;
            left: 0;
            line-height: 1;
            box-sizing: border-box;
            padding: 20px;
            transition: background-color 0.3s ease;
            cursor: pointer;

            .info {
              .title {
                .moreInfo {
                  display: none;
                }
              }
            }
          }
        }

        &.open {
          .row-content {
            .overlay {
              .toProfile {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.block-views-blockdoktori-un-doktoranti-block-2 {
    .views-row {
      .views-col {
        .row-content {
          .overlay {
            background-color: rgba(255, 255, 255, 1);
            line-height: 1;
            box-sizing: border-box;
            padding: 10px 20px 20px 20px;

            .info {
              opacity: 1;
              color: #191919;

              .title {
                color: #191919;
                padding: 0;
                word-spacing: 1px;
              }

              .grads {
                color: #191919;

                &:after {
                  -webkit-transform: translate3d(0, 0, 0);
                  transform: translate3d(0, 0, 0);
                }
              }

              .pap-info {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                opacity: 1;
              }
            }

            .moreInfo {
              display: none;
            }

            .toProfile {
              display: block;
            }
          }
        }


        &:hover {
          .row-content {
            .overlay {
              background-color: rgba(255, 255, 255, 1);
            }
          }
        }

        &.open {
          .row-content {
            .overlay {
              background-color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }

  &.block-views-blockdoktori-un-doktoranti-block-1 {
    h2 {
      text-align: center;
      font-size: 1.250em;
      font-weight: 800;
      text-transform: uppercase;
    }

    .views-row {
      position: relative;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 5px;

      & > div {
        padding-left: 25px;
        padding-right: 25px;
        box-sizing: border-box;
      }

      .views-field-field-doktors-e-pasts {
        margin-top: 10px;
      }

      .views-field-field-doktors-amats {
        line-height: 1.37;
        margin-bottom: 3px;
      }

      .views-field-field-doktors-nozare {
        color: #88d1ce;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }

      .views-field-title {
        position: relative;
        padding-bottom: 20px;
        line-height: 2em;
        height: 7.125em;
        vertical-align: bottom;
        margin-bottom: 20px;

        &:after {
          content: ' ';
          background-color: #88d1ce;
          width: 100%;
          position: absolute;
          height: 2px;
          bottom: 0;
          left: 0;
          transition: all 0.3s ease;
        }

        .title {
          font-weight: 600;
          font-size: 2em;
          line-height: 1em;
          position: absolute;
          bottom: 1em;
        }

        .grads {
          font-size: 1.125em;
          font-style: italic;
          position: absolute;
          bottom: 10px;
          height: 1.125em;
          line-height: 1.125em;
        }
      }

      .toProfile {
        position: relative;
        display: block;
        bottom: 0;
        left: 25px;
        margin: 15px 0 0 0;

        &:after {
          display: inline-block;
          content: '';
          width: 40px;
          height: 10px;
          margin-left: 10px;
          transition: background-position-x 0.3s ease;
          background: url('../images/bior-icon-arrow-right-green.svg') no-repeat 120% center;
        }
      }

      &:hover {
        .views-field-title {
          &:after {
            transform: scaleY(3);
          }
        }
      }
    }

    .view-footer {
      clear: both;
      float: none;
    }
  }
}


@media (min-width: 500px) {
  .block {
    &.block-views-blockdoktori-un-doktoranti-block-4,
    &.block-views-blockdoktori-un-doktoranti-block-2 {
      .views-row {
        .views-col {
          .row-content {
            .overlay {
              .moreInfo {
                left: 40px;
                bottom: 40px;
              }

              .toProfile {
                position: absolute;
                right: 40px;
                bottom: 40px;
                display: none;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    &.block-views-blockdoktori-un-doktoranti-block-2 {
      .views-row {
        .views-col {
          .row-content {
            .toProfile {
              display: none;
            }

            .overlay {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(255, 255, 255, 0);
              top: 0;
              left: 0;
              line-height: 1;
              box-sizing: border-box;
              padding: 20px;
              transition: background-color 0.3s ease;
              cursor: pointer;

              .info {
                .title {
                  color: #fff;
                  padding: 0 100px 0 0;
                  word-spacing: 999px;
                }

                .grads {
                  color: #fff;

                  &:after {
                    -webkit-transform: translate3d(-100%, 0, 0);
                    transform: translate3d(-100%, 0, 0);
                  }
                }

                .pap-info {
                  -webkit-transform: translate3d(0, 40px, 0);
                  transform: translate3d(0, 40px, 0);
                  opacity: 0;
                }
              }

              .moreInfo {
                display: block;
              }
            }
          }

          &:hover {
            .row-content {
              .overlay {
                background-color: rgba(0, 0, 0, 0.2);
              }
            }
          }

          &.open {
            .row-content {
              .toProfile {
                display: block;
              }

              .overlay {
                background-color: rgba(255, 255, 255, 1);

                .info {
                  .grads {
                    &:after {
                      -webkit-transform: translate3d(0, 0, 0);
                      transform: translate3d(0, 0, 0);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 513px) {
  .block {
    &.block-views-blockdoktori-un-doktoranti-block-4,
    &.block-views-blockdoktori-un-doktoranti-block-2 {
      .views-row {
        .views-col {
          .row-content {
            .overlay {
              .info {
                .title {
                  font-size: 2em;
                  padding: 10px 150px 0 20px;
                }

                .grads {
                  padding: 0 220px 20px 20px;
                  font-size: 1.125em;
                  margin-bottom: 10px;
                }

                .pap-info {
                  padding: 0 20px;
                }
              }
            }
          }

          &.open {
            .row-content {
              .overlay {
                .info {
                  .title {
                    padding: 10px 20px 0 20px;
                  }

                  .grads {
                    padding: 0 20px 20px 20px;
                  }
                }

                .moreInfo {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 700px) {
  .block {
    &.block-views-blockdoktori-un-doktoranti-block-1 {
      .views-row {
        float: left;
        width: 50%;
        padding: 20px 10px;
        margin-bottom: 15px;

        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .block {
    &.block-views-blockdoktori-un-doktoranti-block-1 {
      .views-row {
        width: 33.33%;

        &:nth-child(2n+1) {
          clear: none;
        }

        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
}

@media (min-width: 1220px) {
  .block {
    &.block-views-blockdoktori-un-doktoranti-block-2,
    &.block-views-blockdoktori-un-doktoranti-block-4 {
      .views-row {
        .views-col {
          width: 513px;
          margin: 0 10px 20px 10px;
        }
      }
    }
  }
}
