.block {
  &#block-textresize {
    .text-resize-button {
      outline: 0;
      text-align: center;
      background: transparent;
      border: 0;
      display: flex;
      align-items: stretch;
      flex-direction: row;
      flex-flow: initial;

      &__aa {
        display: flex;
        align-items: center;
        margin-right: 10px;

        svg {
          width: 43px;
          height: 45px;
          fill: #b3bfc5;

          @media (max-width: 900px) {
            width: 23px;
            height: 25px;
          }
        }
      }

      &__arrow {
        display: flex;
        align-items: center;

        svg {
          width: 15px;
          height: 30px;
          fill: #b3bfc5;

          @media (max-width: 900px) {
            width: 10px;
            height: 25px;
          }
        }
      }
    }

    @media (max-width: 400px) {
      display: flex;
      width: 50%;
      justify-content: flex-start;
    }

    .text-resize-popup {
      display: none;
      z-index: 16;
      position: absolute;
      background: #FFF;
      top: 100%;
      padding: 20px 0 10px;
      border: 0.5px solid #B3BFC5;

      @media (min-width: 1220px) {
        right: 0;
      }

      a {
        display: block;
        height: auto;
        width: auto;
        font-size: 24px;
        color: #014f52;
        background: none;
        text-align: right;
        text-decoration: none;
        padding: 9px 29px 9px 73px;

        &#text_resize_decrease {
          font-size: 16px;
        }

        &#text_resize_increase {
          font-size: 28px;
        }
      }
    }

    &.open {

      .text-resize-popup {
        display: block;
      }

      .text-resize-button {
        &__arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
}

@media (max-width: 900px) {
  #block-custom-text-size-popup {
    top: 50px;
  }
  .block.block-text-resize {
    display: inline-block;
  }
}
