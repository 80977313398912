.add-to-cart-link {
  display: inline-block;
  color: color(white);
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px;
  margin-top: 15px;
  font-weight: 400;
  background: color(brown);
  font-size: 14px;
  outline: none;

  &:hover, &:active, &:focus {
    background: darken(color(brown), 10%);
    color: color(white);
  }
}
