.bx-wrapper {
  box-shadow: none;
  border: 5px solid $accessPrimary;
  background: $accessSecondary
}

.bx-wrapper .bx-loading {
  background-color: $accessSecondary;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:focus {
  background: $accessSecondary
}

.bx-wrapper .bx-caption {
  background: $accessSecondary;
}

.bx-wrapper .bx-caption span {
  color: $accessPrimary;
}

.slick-loading .slick-list {
  background: $accessSecondary url("./ajax-loader.gif") center center no-repeat
}

.slick-prev:before,
.slick-next:before {
  color: $accessPrimary;
}

.slick-dots li button:before {
  color: $accessPrimary;
}

.slick-dots li.slick-active button:before {
  color: $accessPrimary;
  opacity: .75
}

.jspVerticalBar {
  background: $accessSecondary;
}

.jspHorizontalBar {
  background: $accessSecondary;
}

.jspTrack {
  background: $accessSecondary;
}

.jspDrag {
  background: $accessSecondary;
}

.jspArrow {
  background: $accessSecondary;
}

.jspDisabled {
  background: $accessSecondary;
}

.jspVerticalBar .jspArrow:focus {
  border: 1px solid $accessSecondary;
}

.jspCorner {
  background: $accessSecondary;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid $accessSecondary;
}

.select2-close-mask {
  background-color: $accessSecondary;
}

.select2-container--default .select2-selection--single {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: $accessPrimary;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: $accessPrimary
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: $accessPrimary transparent transparent transparent;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: $accessSecondary;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $accessPrimary transparent;
}

.select2-container--default .select2-selection--multiple {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: $accessPrimary;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: $accessPrimary
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid $accessPrimary 1px;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: $accessSecondary;
}

.select2-search--dropdown {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary
}

.select2-results__option[aria-selected] {
  background-color: $accessSecondary;
  color: $accessPrimary;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: $accessPrimary
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $accessSecondary
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $accessSecondary;
  color: $accessPrimary
}

.select2-container--classic .select2-selection--single {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
  background-image: linear-gradient(to bottom, $accessSecondary 50%, $accessSecondary 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$accessSecondaryfff', endColorstr='$accessSecondaryfff', GradientType=0)
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid $accessPrimary
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: $accessPrimary;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: $accessPrimary
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: $accessSecondary;
  border-left: 1px solid $accessPrimary;
  background-image: linear-gradient(to bottom, $accessSecondary 50%, $accessSecondary 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$accessSecondaryfff', endColorstr='$accessSecondaryfff', GradientType=0)
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: $accessPrimary transparent transparent transparent;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border-right: 1px solid $accessPrimary;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid $accessPrimary
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $accessPrimary transparent;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  background-image: linear-gradient(to bottom, $accessSecondary 0%, $accessSecondary 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$accessSecondaryfff', endColorstr='$accessSecondaryfff', GradientType=0)
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  background-image: linear-gradient(to bottom, $accessSecondary 50%, $accessSecondary 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$accessSecondaryfff', endColorstr='$accessSecondaryfff', GradientType=0)
}

.select2-container--classic .select2-selection--multiple {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid $accessPrimary
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: $accessPrimary;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: $accessPrimary
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid $accessPrimary
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid $accessPrimary;
}

.select2-container--classic .select2-dropdown {
  background-color: $accessSecondary;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: $accessSecondary
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: $accessSecondary;
  color: $accessPrimary
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: $accessPrimary
}

.fa-border {
  border: solid 0.08em $accessSecondary;
}

.fa-inverse {
  color: $accessSecondary
}

.custom-select {
  border: 1px solid $accessPrimary;
  background: $accessSecondary;
}

.custom-select a {
  color: $accessPrimary;
}

.custom-select > div {
  border: 1px solid $accessPrimary;
  background: $accessSecondary;
}

.custom-select input {
  border: 1px solid $accessPrimary;
}

.custom-select div ul li:hover {
  background: $accessPrimary;
  color: $accessSecondary
}

.custom-select div ul li.option-hover {
  background: $accessPrimary;
  color: $accessSecondary
}

.custom-select div ul li.option-disabled {
  color: $accessPrimary
}

.custom-select div ul li.option-disabled:hover {
  background: $accessPrimary;
  color: $accessSecondary
}

.custom-select div ul li.option-hover.option-disabled {
  background: $accessPrimary;
  color: $accessSecondary
}

.custom-select div ul li.no-results {
  background: $accessSecondary;
  color: $accessPrimary
}

.custom-select-open {
  border-bottom: 1px solid $accessPrimary
}

body {
  background: $accessSecondary;
  color: $accessPrimary;
}

ul li {
  list-style-image: url("../images/#{$assetsFolder}/bior-bullet.svg")
}

a {
  color: $accessPrimary;
}

a.redFull {
  color: $accessPrimary;
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icon-arrow-right.svg") no-repeat 98% center;
  border: 2px solid $accessPrimary;
}

a:hover {
  color: $accessPrimary
}

a:hover.redFull {
  color: $accessPrimary;
}

a.button-link {
  background: $accessSecondary;
  color: $accessPrimary;
}

a.button-link:hover,
a.button-link:focus,
a.button-link:active {
  background: $accessSecondary
}

a.pdf-button {
  color: $accessPrimary;
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

a.pdf-button:hover,
a.pdf-button:focus,
a.pdf-button:active {
  color: $accessSecondary;
  background: $accessPrimary
}

.region-breadcrumb .block .breadcrumb {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
}

.region-breadcrumb .block .breadcrumb li:last-child {
  color: $accessPrimary
}

.region-breadcrumb .block .breadcrumb li:last-child a {
  color: $accessPrimary;
}

.region-breadcrumb .block .breadcrumb li a {
  color: $accessPrimary;
}


.region-sidebar-second {
  background-color: $accessSecondary
}

.layout-content-bottom {
  background-color: $accessSecondary
}

.layout-content-bottom h2 {
  color: $accessPrimary
}

.region-front-galleries .toGallery a {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
  transition: all 0.5s ease;
}

.region-front-galleries .toGallery a:hover {
  background-color: $accessPrimary;
  color: $accessSecondary;
}

.region-cenradis-wrapper .region-cenradis {
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

.menu--main .menu__back {
  color: $accessPrimary;
}

.menu--main .menu__back:before {
  border-left: 1px solid $accessPrimary;
}

.menu--main .menu__back:hover,
.menu--main .menu__back:focus {
  color: $accessPrimary;
}

.menu--main .menu__breadcrumbs {
  border-bottom: 1px solid $accessPrimary;
}

.menu--main .menu__breadcrumbs a {
  color: $accessPrimary
}

.menu--main .menu__breadcrumbs a:not(:last-child)::after {
  color: $accessPrimary
}


.menu--main .menu__wrap .jspTrack {
  background: $accessPrimary
}

.menu--main .menu__wrap a {
  color: $accessPrimary;
}

.menu--main .menu__wrap a[data-submenu]::after {
  background: url("../images/#{$assetsFolder}/bior-menu-arrow.svg") no-repeat
}

.menu--main .menu__wrap a:hover {
  color: $accessPrimary
}

.menu--main .menu__wrap a[data-submenu]:hover::after {
  color: $accessPrimary
}

html[lang="lv"] .menu--main .menu__wrap a.is-active:not([href="/lv"]),
html[lang="lv"] .menu--main .menu__wrap a.is-active:not([href="/en"]),
html[lang="lv"] .menu--main .menu__wrap a.menu__link--current:not([href="/ru"]),
html[lang="en"] .menu--main .menu__wrap a.is-active:not([href="/lv"]),
html[lang="en"] .menu--main .menu__wrap a.is-active:not([href="/en"]),
html[lang="en"] .menu--main .menu__wrap a.menu__link--current:not([href="/ru"]),
html[lang="ru"] .menu--main .menu__wrap a.is-active:not([href="/lv"]),
html[lang="ru"] .menu--main .menu__wrap a.is-active:not([href="/en"]),
html[lang="ru"] .menu--main .menu__wrap a.menu__link--current:not([href="/ru"]),
html[lang="ru"] .menu--main .menu__wrap a.is-active:not([href="/lv"]),
html[lang="ru"] .menu--main .menu__wrap a.is-active:not([href="/en"]),
html[lang="ru"] .menu--main .menu__wrap a.menu__link--current:not([href="/ru"]) {
  color: $accessPrimary;
}

.field--name-body h2,
.field--name-field-vadiba-saturs h2,
.field--type-text-long h2 {
  color: $accessPrimary;
}

.field--name-body h2:before,
.field--name-field-vadiba-saturs h2:before,
.field--type-text-long h2:before {
  background-color: $accessSecondary
}

.field--name-body figcaption,
.field--name-field-vadiba-saturs figcaption,
.field--type-text-long figcaption {
  background-color: $accessSecondary
}

.field--name-body table caption,
.field--name-field-vadiba-saturs table caption,
.field--type-text-long table caption {
  color: $accessPrimary;
}

.field--name-body table thead,
.field--name-field-vadiba-saturs table thead,
.field--type-text-long table thead {
  background-color: $accessSecondary;
  color: $accessPrimary
}


.field--name-body table tr th,
.field--name-body table tr td,
.field--name-field-vadiba-saturs table tr th,
.field--name-field-vadiba-saturs table tr td,
.field--type-text-long table tr th,
.field--type-text-long table tr td {
  border-bottom: 1px solid $accessPrimary
}

.field--name-body table tfoot,
.field--name-field-vadiba-saturs table tfoot,
.field--type-text-long table tfoot {
  background-color: $accessSecondary
}


.field--name-body a[href^="http://"]:before,
.field--name-body a[href^="https://"]:before,
.field--name-field-vadiba-saturs a[href^="http://"]:before,
.field--name-field-vadiba-saturs a[href^="https://"]:before,
.field--type-text-long a[href^="http://"]:before,
.field--type-text-long a[href^="https://"]:before {
  background: url("../images/#{$assetsFolder}/icon-link.svg") no-repeat;
}

.field--name-body a[href$=".docx"]:before,
.field--name-body a[href$=".doc"]:before,
.field--name-field-vadiba-saturs a[href$=".docx"]:before,
.field--name-field-vadiba-saturs a[href$=".doc"]:before,
.field--type-text-long a[href$=".docx"]:before,
.field--type-text-long a[href$=".doc"]:before {
  background: url("../images/#{$assetsFolder}/icon-word.svg") no-repeat;
}

.field--name-body a[href$=".xls"]:before,
.field--name-body a[href$=".xlsx"]:before,
.field--name-field-vadiba-saturs a[href$=".xls"]:before,
.field--name-field-vadiba-saturs a[href$=".xlsx"]:before,
.field--type-text-long a[href$=".xls"]:before,
.field--type-text-long a[href$=".xlsx"]:before {
  background: url("../images/#{$assetsFolder}/icon-xls.svg") no-repeat;
}

.field--name-body a[href$=".pdf"]:before,
.field--name-field-vadiba-saturs a[href$=".pdf"]:before,
.field--type-text-long a[href$=".pdf"]:before {
  background: url("../images/#{$assetsFolder}/icon-pdf.svg") no-repeat;
}

.jspTrack {
  background: $accessSecondary;
}

.jspDrag {
  background: $accessSecondary;
}

.block-user-login-block h2 {
  background: $accessSecondary;
  color: $accessPrimary !important;
}

.block-user-login-block .user-login-form-wrapper {
  background: $accessSecondary;
}

.block-user-login-block .user-login-form-wrapper .item-list a {
  color: $accessPrimary
}

.tabs a {
  border: 1px solid transparent;
}

.tabs a.is-active,
.tabs a:focus, .tabs a:hover {
  border: 1px solid $accessPrimary;
  background-color: $accessSecondary;
}

.user-login-form {
  background-color: $accessSecondary
}

.user-login-form .form-item label {
  color: $accessPrimary
}

.user-login-form input,
.user-pass input,
.user-pass input:focus-visible {
  color: $accessPrimary;
  background-color: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.user-login-form .form-actions input {
  background: $accessSecondary;
  color: $accessPrimary;
}

.comment-wrapper .form-item textarea {
  background-color: $accessSecondary;
}

.comment-wrapper .title {
  color: $accessPrimary;
}

.comment-wrapper .links.inline a {
  color: $accessPrimary;
}

.comment-wrapper .form-actions .button--primary {
  color: $accessPrimary;
  background-color: $accessSecondary;
}

.comment {
  border-bottom: 1px solid $accessPrimary;
}

.comment .comment__author {
  color: $accessPrimary;
}


.comment .comment__time {
  color: $accessPrimary;
}

.path-contact .region-content {
  background-color: $accessSecondary;
}

.side-nav .side-nav__hide {
  color: $accessPrimary;
}

.side-nav .side-nav__header {
  background: $accessSecondary;
  color: $accessPrimary;
}

.side-nav .side-nav__content li:hover {
  background: $accessSecondary
}

.side-nav .side-nav__container {
  background: $accessSecondary;
}

.side-nav #closeSidebar {
  color: $accessPrimary;
}

.side-nav a {
  color: $accessPrimary
}

.side-nav a:hover {
  color: $accessPrimary
}

main .block.block-menu.navigation,
.region-breadcrumb .block.block-menu.navigation {
  border-bottom: 2px solid $accessPrimary;
  border-top: 2px solid $accessPrimary;
}

main .block.block-menu.navigation .menu__wrap a[data-submenu]::after,
.region-breadcrumb .block.block-menu.navigation .menu__wrap a[data-submenu]::after {
  background: url("../images/#{$assetsFolder}/bior-menu-arrow.svg") no-repeat
}

main .block.block-menu.navigation h2,
.region-breadcrumb .block.block-menu.navigation h2 {
  color: $accessPrimary;
}


main .block.block-menu.navigation > ul.menu li:after,
.region-breadcrumb .block.block-menu.navigation > ul.menu li:after {
  background-color: $accessSecondary
}

main .block.block-menu.navigation > ul.menu li a,
.region-breadcrumb .block.block-menu.navigation > ul.menu li a {
  color: $accessPrimary;
}

main .block.block-menu.navigation > ul.menu li a:hover,
.region-breadcrumb .block.block-menu.navigation > ul.menu li a:hover {
  color: $accessPrimary
}

main .block.block-menu.navigation > ul.menu li.group-lock a:after,
.region-breadcrumb .block.block-menu.navigation > ul.menu li.group-lock a:after {
  background-color: $accessSecondary;
}

main .block.block-menu.navigation > ul.menu li.group-lock a:after,
.region-breadcrumb .block.block-menu.navigation > ul.menu li.group-lock a:after {
  background-image: url("../images/#{$assetsFolder}/atsledzina-01.svg")
}

footer .footer-sazinies-button a:hover .st9 {
  fill: $accessSecondary
}

footer .footer-two .getRoute a {
  background: $accessSecondary;
  color: $accessPrimary;
}

footer .footer-two .getRoute a:hover {
  background-color: $accessSecondary;
  color: $accessPrimary
}

footer .footer-three {
  border-top: 2px solid $accessPrimary
}

footer .footer-three #block-biorlogo .st0 {
  fill: $accessSecondary
}

footer .footer-three #block-biorlogo .st1 {
  fill: $accessSecondary
}

.webform-submission-form {
  background-color: $accessSecondary;
}

.webform-submission-form input,
.webform-submission-form textarea {
  border: 2px solid $accessPrimary;
  color: $accessPrimary;
}

.webform-submission-form input::-moz-placeholder,
.webform-submission-form textarea::-moz-placeholder {
  color: $accessPrimary;
}

.webform-submission-form input::-ms-input-placeholder,
.webform-submission-form textarea::-ms-input-placeholder {
  color: $accessPrimary;
}

.webform-submission-form input::placeholder,
.webform-submission-form textarea::placeholder {
  color: $accessPrimary;
}

.webform-submission-form .groupInfo {
  border-bottom: 1px solid $accessPrimary;
}

.webform-submission-form .form-type-radio input[type=radio] + label:before,
.webform-submission-form .form-type-radio input[type=checkbox] + label:before,
.webform-submission-form .form-type-checkbox input[type=radio] + label:before,
.webform-submission-form .form-type-checkbox input[type=checkbox] + label:before {
  border: 2px solid $accessPrimary;
}

.webform-submission-form label {
  color: $accessPrimary;
  font-size: 16px
}

.webform-submission-form label.error {
  color: #b25d5d
}

.webform-submission-form select {
  border: 2px solid $accessPrimary;
  color: $accessPrimary;
  background: $accessSecondary;
}


.webform-submission-form em {
  color: $accessPrimary;
}

.webform-submission-form .messages {
  color: $accessPrimary
}

.webform-submission-form .form-managed-file {
  color: $accessPrimary
}

.webform-submission-form .form-managed-file .form-submit {
  background-image: url("../images/#{$assetsFolder}/bior-sidebar-close.png");
}

.webform-submission-form .webform-file-button:before {
  background-image: url("../images/#{$assetsFolder}/bior-icon-upload.png");
  color: $accessPrimary
}

.webform-submission-form .form-actions input.form-submit,
.webform-submission-form .form-actions input.form-submit:hover {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
}

form.bef-exposed-form .form-select {
  color: $accessPrimary;
  border-bottom: 2px solid $accessPrimary;
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icons-select-arrow.svg") no-repeat 95% center
}

form.bef-exposed-form .form-text {
  background: $accessSecondary;
  border-bottom: 2px solid $accessPrimary
}

form.bef-exposed-form .form-actions input.form-submit {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
}

form.bef-exposed-form .bef-links a:after {
  color: $accessPrimary
}


form.bef-exposed-form .bef-links a.bef-link--selected {
  color: $accessPrimary;
}

form.bef-exposed-form .bef-links#edit-gads a {
  color: $accessPrimary;
}

form.bef-exposed-form .bef-links#edit-gads a:hover {
  color: $accessPrimary
}

form.bef-exposed-form .bef-links#edit-gads a.bef-link--selected {
  color: $accessPrimary;
}

form.bef-exposed-form .bef-links#edit-gads a:after {
  background-color: $accessSecondary;
}

.block-language ul.links li a {
  color: $accessPrimary;
}

.view-jaunumi .views-row {
  border: 2px solid $accessPrimary;
}

.view-jaunumi .views-row .toLists {
  background: url("../images/#{$assetsFolder}/bior-event-icon.svg");
  background-color: $accessSecondary
}

.view-jaunumi .views-row .toLists span {
  background: $accessSecondary
}

.view-jaunumi .views-row .toLists svg polyline {
  stroke: $accessSecondary;
}

.view-jaunumi .views-row.type-notikums {
  border: 2px solid $accessPrimary
}

.view-jaunumi .views-row.type-notikums .toLists svg polyline {
  stroke: $accessSecondary
}

.view-jaunumi .views-row.type-notikums .link a {
  background-image: url("../images/#{$assetsFolder}/bior-icon-arrow-right.svg")
}

.view-jaunumi .views-row.type-article .toLists {
  background: url("../images/#{$assetsFolder}/bior-news-icon.svg")
}

.view-jaunumi .views-row.type-article .toLists span {
  background: $accessPrimary;
}

.view-jaunumi .views-row.type-article .type {
  color: $accessPrimary
}

.view-jaunumi .title a {
  color: $accessPrimary;
}

.view-jaunumi .link a {
  background: url("../images/#{$assetsFolder}/bior-icon-arrow-right.svg") no-repeat center right;
}

.view-jaunumi .vieta:before {
  background: url("../images/#{$assetsFolder}/bior-vieta-icon.svg")
}

.view-jaunumi .laiks:before {
  background: url("../images/#{$assetsFolder}/bior-clock-icon.svg")
}

.view-jaunumi .linkButton {
  border: 1px solid $accessSecondary;
  background: $accessSecondary;
}

.view-jaunumi .linkButton:hover {
  background: $accessSecondary;
  color: $accessPrimary
}

.view-jaunumi .linkButton.inside {
  border: 1px solid $accessSecondary;
  color: $accessPrimary;
  background: $accessSecondary
}

.view-jaunumi .linkButton.inside:hover {
  background: $accessSecondary;
  color: $accessPrimary
}

.view-jaunumi .type a {
  color: $accessPrimary;
}

.view-jaunumi .type a:hover {
  color: $accessPrimary
}

.view-jaunumi .type .seperator:before {
  background-color: $accessSecondary;
}

.view-jaunumi .view-footer a {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
  transition: all 0.5s ease;
}

.view-jaunumi .view-footer a:hover {
  background-color: $accessPrimary;
  color: $accessSecondary;
}

header .search-api-page-block-form .block-bior-theme-search,
header .block-views-exposed-filter-blocksearch-search-api-page-block-form .block-bior-theme-search {
  background: $accessSecondary;
}

header .search-api-page-block-form .block-bior-theme-search .closeIcon,
header .block-views-exposed-filter-blocksearch-search-api-page-block-form .block-bior-theme-search .closeIcon {
  background: url("../images/#{$assetsFolder}/bior-icons-search-close.svg") no-repeat center center;
}


header .search-api-page-block-form .block-bior-theme-search form .form-item .form-text,
header .block-views-exposed-filter-blocksearch-search-api-page-block-form .block-bior-theme-search form .form-item .form-text {
  border-color: $accessPrimary;
  background: $accessSecondary
}

header .search-api-page-block-form .block-bior-theme-search form .form-actions,
header .block-views-exposed-filter-blocksearch-search-api-page-block-form .block-bior-theme-search form .form-actions {
  background: url("../images/#{$assetsFolder}/bior-search-icon.svg") no-repeat center center;
}

.search-api-autocomplete-search .ui-menu-item-wrapper:hover,
.search-api-autocomplete-search .ui-menu-item-wrapper.ui-state-active {
  background-color: $accessSecondary;
  color: $accessPrimary;
}

.search-api-autocomplete-search .ui-menu-item-wrapper:hover .search-api-autocomplete-suggestion,
.search-api-autocomplete-search .ui-menu-item-wrapper.ui-state-active .search-api-autocomplete-suggestion {
  background-color: $accessSecondary
}

.banner-section .region-front-banners {
  border-top: 2px solid $accessSecondary;
  border-bottom: 2px solid $accessSecondary
}

.banner-section .region-front-banners .block .view-banneri- .slick-prev {
  background: url("../images/#{$assetsFolder}/bior-baner-left.png")
}

.banner-section .region-front-banners .block .view-banneri- .slick-next {
  background: url("../images/#{$assetsFolder}/bior-baner-right.png")
}

.block .view-banneru-slaideris .view-content .views-row .views-field-field-banner-image:after {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 47%, rgba(0, 0, 0, 0.77) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$accessPrimary00000', endColorstr='$accessPrimary000', GradientType=0)
}

.block .view-banneru-slaideris .view-content .views-row .views-field-title {
  color: $accessPrimary;
}

.block#block-totop #toTop {
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icon-arrow-top.svg") no-repeat center center;
}

.block#block-totop #toTop:hover {
  background-color: $accessSecondary
}


.block.block-views-blocksakums-galeriju-bloki-block-1 .view-content .views-row .views-field-title a,
.block.block-views-blocksakums-galeriju-bloki-block-2 .view-content .views-row .views-field-title a,
.block.block-views-blockviens-galerijas-video-block-1 .view-content .views-row .views-field-title a {
  color: $accessPrimary;
  background-color: $accessSecondary;
}


.block.block-views-blocksakums-galeriju-bloki-block-1 .view-content .views-row .views-field-view-node a,
.block.block-views-blocksakums-galeriju-bloki-block-2 .view-content .views-row .views-field-view-node a,
.block.block-views-blockviens-galerijas-video-block-1 .view-content .views-row .views-field-view-node a {
  color: $accessPrimary;
  background-color: $accessSecondary;
}

.block.block-views-blocksakums-galeriju-bloki-block-1 .view-content .views-row .views-field-view-node a:after,
.block.block-views-blocksakums-galeriju-bloki-block-2 .view-content .views-row .views-field-view-node a:after,
.block.block-views-blockviens-galerijas-video-block-1 .view-content .views-row .views-field-view-node a:after {
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-view-gallery.png") no-repeat center right;
}

.block.block-views-blocksakums-galeriju-bloki-block-1 .view-content .views-row.type-video-galerija:after,
.block.block-views-blocksakums-galeriju-bloki-block-2 .view-content .views-row.type-video-galerija:after,
.block.block-views-blockviens-galerijas-video-block-1 .view-content .views-row.type-video-galerija:after {
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icons-video.svg") no-repeat center center
}

.block.block-views-blocksakums-galeriju-bloki-block-1 .view-content .views-row:after,
.block.block-views-blocksakums-galeriju-bloki-block-2 .view-content .views-row:after,
.block.block-views-blockviens-galerijas-video-block-1 .view-content .views-row:after {
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icons-photo.svg") no-repeat center center
}


.block.block-views-blocksakums-galeriju-bloki-block-1 .view-footer a,
.block.block-views-blocksakums-galeriju-bloki-block-2 .view-footer a,
.block.block-views-blockviens-galerijas-video-block-1 .view-footer a {
  background-color: $accessSecondary;
  color: $accessPrimary;
}

.block.block-views-blockviens-galerijas-video-block-1 .view-content .views-row:after {
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icons-video.svg") no-repeat center center
}

.block.block-views-blockfront-4-bloki-block-1 .views-row {
  background: $accessSecondary;
}

.block.block-views-blockfront-4-bloki-block-1 .views-row .views-field-title a,
.block.block-views-blockfront-4-bloki-block-1 .views-row .views-field-field-link a {
  border: 2px solid $accessPrimary;
  color: $accessPrimary;
}

.block.block-views-blockfront-4-bloki-block-1 .views-row:hover {
  background-color: $accessPrimary;
}

.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .views-field-title a,
.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .views-field-field-link a {
  border-color: $accessPrimary;
  color: $accessPrimary
}

.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .views-field-title a,
.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .views-field-field-link a {
  border-color: $accessSecondary;
  color: $accessSecondary;
}

.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .st0,
.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .st1,
.block.block-views-blockfront-4-bloki-block-1 .views-row:hover .st2 {
  fill: $accessSecondary;
}

.block.block-views-blockpetijumi-block-1 .views-row,
.block.block-views-blockinfrastrukturas-saraksts-block-1 .views-row {
  background-color: $accessSecondary;
}

.block.block-views-blockpetijumi-block-1 .views-row .views-field-title a,
.block.block-views-blockinfrastrukturas-saraksts-block-1 .views-row .views-field-title a {
  color: $accessPrimary;
}

.block.block-views-blockpetijumi-block-1 .views-row .views-field-view-node a,
.block.block-views-blockinfrastrukturas-saraksts-block-1 .views-row .views-field-view-node a {
  color: $accessPrimary;
}

.block.block-views-blockpetijumi-block-1 .views-row .views-field-view-node a:before,
.block.block-views-blockinfrastrukturas-saraksts-block-1 .views-row .views-field-view-node a:before {
  border: 2px solid $accessSecondary;
  background: url("../images/cross.svg");
}

.block.block-views-blockpetijumi-block-1 .views-row:hover,
.block.block-views-blockinfrastrukturas-saraksts-block-1 .views-row:hover {
  background-color: $accessSecondary;
}

.block.block-views-blockpakalpojumi-block-1 .views-row {
  border-bottom: 2px solid $accessSecondary;
}

.block.block-views-blockpakalpojumi-block-1 .views-row .views-field-title:before {
  background: $accessSecondary;
}

.block.block-views-blockpakalpojumi-block-1 .views-row .views-field-title:after {
  background: $accessSecondary;
}

.block.block-views-blockpakalpojumi-block-1 .views-row .views-field-nothing table tr {
  border-bottom: 1px solid #b25d5b
}

.block.block-views-blockpakalpojumi-block-1 .views-row .views-field-nothing table tr td:nth-child(even) {
  border-left: 2px solid #b25d5b;
}

.block.block-views-blockpakalpojumi-block-1 .views-row .redLine {
  border-left: 2px solid #b25d5b
}

.block.block-views-blockpakalpojumi-block-1 .views-row.open .views-field-title:before,
.block.block-views-blockpakalpojumi-block-1 .views-row.open .views-field-title:after {
  background-color: $accessSecondary;
}


.block.block-views-blocklizigie-jaunumi-block-1 .views-row,
.block.block-views-blocklizigie-jaunumi-block-2 .views-row {
  border-bottom: 1px solid $accessPrimary
}

.block.block-views-blockpublikacijas-block-1 table th {
  background-color: $accessSecondary;
  color: $accessPrimary;
}

.block.block-views-blockpublikacijas-block-1 table tr {
  border-bottom: 2px solid $accessSecondary;
}

.block.block-views-blockpublikacijas-block-1 table tr:hover {
  background-color: $accessSecondary
}

.block.block-views-blockpublikacijas-block-1 .views-field-field-publikacija-datu-baze span {
  background: url("../images/#{$assetsFolder}/bior-checked-icon.svg") no-repeat center center;
}

.block.block-views-blockpublikacijas-block-1 .views-field-nothing div a {
  background: url("../images/#{$assetsFolder}/icon-link.svg") no-repeat center center;
}

.block.block-views-blockpublikacijas-block-1 .views-field-nothing a[href$=".pdf"] {
  background: url("../images/#{$assetsFolder}/icon-pdf.svg") no-repeat
}

.block.block-views-blockvakances-block-1 .item-list ul li {
  list-style-image: url("../images/#{$assetsFolder}/bior-bullet.svg");
}

.block.block-views-blockvakances-block-1 .item-list ul li li {
  list-style-image: url("../images/#{$assetsFolder}/bior-bullet.svg")
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .info .title,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .title {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .info .grads,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .grads {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .info .grads:after,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .grads:after {
  background: $accessSecondary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .info .pap-info .nozare,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .pap-info .nozare {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .moreInfo,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .moreInfo {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .moreInfo .box:after,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .moreInfo .box:after {
  background-color: $accessSecondary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .moreInfo .box:before,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .moreInfo .box:before {
  background-color: $accessSecondary
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .toProfile:after,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .toProfile:after {
  background: url("../images/#{$assetsFolder}/bior-icon-arrow-right.svg") no-repeat 120% center
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col:hover .row-content .overlay,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col:hover .row-content .overlay {
  background-color: $accessSecondary
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col.open .row-content .overlay,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col.open .row-content .overlay {
  background-color: $accessSecondary
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col.open .row-content .overlay .info .title,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col.open .row-content .overlay .info .title {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col.open .row-content .overlay .info .grads,
.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col.open .row-content .overlay .info .grads {
  color: $accessPrimary
}


.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay {
  background-color: $accessSecondary;
}

.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay {
  background-color: $accessSecondary;
}

.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info {
  color: $accessPrimary
}

.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .title {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .grads {
  color: $accessPrimary
}

.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col:hover .row-content .overlay {
  background-color: $accessSecondary
}

.block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col.open .row-content .overlay {
  background-color: $accessSecondary
}

.block.block-views-blockdoktori-un-doktoranti-block-1 .views-row .views-field-field-doktors-nozare {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-1 .views-row .views-field-title:after {
  background-color: $accessSecondary;
}

.block.block-views-blockdoktori-un-doktoranti-block-1 .views-row .toProfile:after {
  background: url("../images/#{$assetsFolder}/bior-icon-arrow-right.svg") no-repeat 120% center
}


@media (min-width: 500px) {
  .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay {
    background-color: $accessSecondary;
  }

  .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .title {
    color: $accessPrimary;
  }

  .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .info .grads {
    color: $accessPrimary
  }

  .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col.open .row-content .overlay {
    background-color: $accessSecondary
  }
}

.block.block-views-blockadministracijas-kontakti-block-1 .views-row .views-field.views-field-field-kontakts-vards-uzvards:after,
.block.block-views-blockadministracijas-kontakti-block-3 .views-row .views-field.views-field-field-kontakts-vards-uzvards:after,
.block.block-views-blockkontakti-saraksts-block-1 .views-row .views-field.views-field-field-kontakts-vards-uzvards:after {
  background-color: $accessSecondary;
}

.block.block-views-blockkontakti-saraksts-block-1 .views-row .views-field.views-field-title:after {
  background-color: $accessSecondary;
}

.block.block-bior-footer-contact .contactUs a.facebook:hover svg .st9 {
  fill: $accessPrimary
}

.block-facet-blockkategorija .facets-widget-checkbox > ul > li > label {
  background: $accessSecondary;
  color: $accessPrimary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget-:before {
  color: $accessSecondary;
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget-:after,
.block-facet-blockdzivnieka-veids .item-list__checkbox:after,
.block-facet-blockslimibas .item-list__checkbox:after {
  background: url("../images/#{$assetsFolder}/bior-icon-cenas-down.svg") no-repeat center right 15px !important;
}
.block-facet-blockdzivnieka-veids .item-list__checkbox.dropdown-visible:after, .block-facet-blockslimibas .item-list__checkbox.dropdown-visible:after{
  background: url("../images/#{$assetsFolder}/bior-icon-cenas-up.svg") no-repeat center right 15px !important;
}

.block-facet-blockkategorija .facets-widget-checkbox > ul > li.facet-item > label {
  background: $accessSecondary;
  color: $accessPrimary;
  border-bottom: 2px solid $accessPrimary;
}

.block-facet-blockkategorija .facets-widget-checkbox > ul > li.facet-item--expanded > label[for=kategorija-1] {
  border-right: 2px solid $accessPrimary;
  border-bottom: 2px solid transparent;
}

.block-facet-blockkategorija .facets-widget-checkbox > ul > li.facet-item--expanded > label[for=kategorija-3] {
  border-left: 2px solid $accessPrimary;
  border-bottom: 2px solid transparent;
}

.block-facet-blockkategorija .facets-widget-checkbox > ul > li > label {
  background: $accessSecondary
}

.block-facet-blockkategorija .facets-widget-checkbox .facet-item .facet-item label {
  border-bottom: 1px solid $accessSecondary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facet-item .facet-item label:hover {
  background: $accessSecondary
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget- .facet-item > label {
  background: $accessSecondary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget- .facet-item.facet-item--expanded > label:before {
  background: transparent;
  color: $accessPrimary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget- .facet-item:not(.facet-item--expanded) > a.is-active {
  background: $accessSecondary;
  border: 2px solid $accessSecondary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget-.dropdown-visible > ul > li > label {
  border-left: 2px solid $accessPrimary;
  border-right: 2px solid $accessPrimary;
  border-bottom: 1px solid $accessPrimary;
  border-top: 1px solid $accessPrimary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget-.dropdown-visible > ul > li:last-child > label {
  border-bottom: 2px solid $accessPrimary;
}

.block-facet-blockkategorija .facets-widget-checkbox .facets-widget- .facet-item:not(.facet-item--expanded) > a.is-active:before {
  background: $accessSecondary;
  color: $accessPrimary;
  width: 406px;
}

@media (min-width: 980px) {
  .block-facet-blockkategorija .facets-widget-checkbox .facets-widget-.dropdown-visible:before {
    width: 406px;
  }

  .block-facet-blockkategorija .facets-widget-checkbox .facets-widget-.dropdown-visible:after {
    background-color: $accessSecondary;
    border: 2px solid $accessPrimary;
    width: 406px;
  }
}

@media (max-width: 979px) {
  .block-facet-blockkategorija .facets-widget-checkbox > ul > .facet-item--expanded > .facets-widget- {
    background: $accessSecondary
  }
}

@media (max-width: 979px) {
  .block-facet-blockkategorija .facets-widget- > ul > .facet-item--expanded > .facets-widget- {
    background: $accessSecondary
  }
}


@media (max-width: 979px) {
  .block-facet-blockkategorija .facets-widget- > ul > .facet-item--expanded > .facets-widget- > ul > .facet-item--expanded > .facets-widget- {
    background: $accessSecondary
  }
}

@media (max-width: 979px) {
  .block-facet-blockkategorija .facets-widget- > ul > .facet-item--expanded > .facets-widget- > ul > .facet-item--expanded > .facets-widget- > ul > .facet-item--expanded > .facets-widget- {
    background: $accessSecondary
  }
}


.js .dropbutton-multiple .dropbutton-widget {
  background-color: $accessSecondary;
}

.js .dropbutton-multiple .dropbutton {
  color: $accessPrimary
}

.js .dropbutton-multiple a {
  color: $accessPrimary;
}

.region-cenradis-wrapper .views-exposed-form,
.view.view-cenradis .views-exposed-form {
  background: $accessSecondary;
}

.region-cenradis-wrapper .views-exposed-form input[name="search"], .view.view-cenradis .views-exposed-form input[name="search"] {
  color: $accessPrimary;
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

::placeholder {
  color: $accessPrimary;
  opacity: 1;
}

.add-to-cart-link {
  color: $accessPrimary;
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

.add-to-cart-link:hover,
.add-to-cart-link:active,
.add-to-cart-link:focus {
  background: $accessPrimary;
  color: $accessSecondary;
}

.block-facet-blockslimibas h3 {
  color: $accessPrimary;
}

.block-facet-blockslimibas .facets-widget-checkbox > ul,
.block-facet-blockslimibas .facets-widget-dropdown > ul {
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

.block-facet-blockdzivnieka-veids h3 {
  color: $accessPrimary;
}

.block-facet-blockdzivnieka-veids .facets-widget-checkbox > ul,
.block-facet-blockdzivnieka-veids .facets-widget-dropdown > ul {
  background: $accessSecondary;
  border: 2px solid $accessPrimary;
}

.block-facet-blockdzivnieka-veids .item-list__checkbox .facet-item,
.block-facet-blockslimibas .item-list__checkbox .facet-item {
  background: $accessSecondary;
}

.block-facet-blockdzivnieka-veids .item-list__checkbox a.is-active:before,
.block-facet-blockslimibas .item-list__checkbox a.is-active:before {
  background: $accessSecondary;
  color: $accessPrimary;
}

.block-facet-blockdzivnieka-veids .item-list__checkbox label,
.block-facet-blockslimibas .item-list__checkbox label {
  border-bottom: 1px solid $accessSecondary;
}

.block-facet-blockdzivnieka-veids .item-list__checkbox.dropdown-visible,
.block-facet-blockslimibas .item-list__checkbox.dropdown-visible {
  box-shadow: 0px 0px 2px $accessSecondary
}

body.page-node-type-galerija #imageGallery {
  background: $accessSecondary;
}

body.page-node-type-galerija #imageGallery .col1 {
  background: $accessSecondary;
}

body.page-node-type-galerija #imageGallery .gallPrev,
body.page-node-type-galerija #imageGallery .gallNext {
  background: transparent url("../images/#{$assetsFolder}/bior-icon-arrow-right.svg") no-repeat calc(100% - 20px) center;
}

body.page-node-type-galerija #imageGallery .closeGallery {
  background: $accessSecondary url("../images/#{$assetsFolder}/bior-icons-galerija-close.svg") no-repeat center center;
}

body.page-node-type-galerija #imageGallery .closeGallery:hover {
  background-color: $accessSecondary
}

body.path-search main .block-bior-theme-search .search-api-page-block-form .form-item .form-text {
  border-top: 1px solid $accessPrimary;
  border-bottom: 1px solid $accessPrimary;
}

body.path-search main .block-bior-theme-search .search-api-page-block-form .form-actions {
  background: url("../images/#{$assetsFolder}/bior-search-icon.svg") no-repeat center center;
}

body.path-search main .search-results {
  background: $accessSecondary;
}

body.path-search main .search-results .search-result__snippet-info {
  border-bottom: 1px solid $accessPrimary;
}

body.path-group article .webform-submission-form .messages {
  color: $accessPrimary
}

body.path-group article .webform-submission-form .form-managed-file .button-action {
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
}

body.path-group article .webform-submission-form .form-managed-file .form-type-checkbox input a,
body.path-group article .webform-submission-form .form-managed-file .form-type-checkbox label a {
  color: $accessPrimary;
}

body.path-group article .webform-submission-form .form-managed-file .form-submit {
  color: $accessPrimary;
}

body.path-group table.views-table thead,
body.path-group table.responsive-enabled thead {
  background-color: $accessSecondary
}

body.path-group table.views-table thead th,
body.path-group table.views-table thead td,
body.path-group table.responsive-enabled thead th,
body.path-group table.responsive-enabled thead td {
  color: $accessPrimary
}

body.path-group table.views-table thead th a,
body.path-group table.views-table thead td a,
body.path-group table.responsive-enabled thead th a,
body.path-group table.responsive-enabled thead td a {
  color: $accessPrimary
}


body.path-group table.views-table tr,
body.path-group table.responsive-enabled tr {
  border-bottom: 1px solid $accessPrimary
}

body.path-group .group__content h2.main-group-title {
  color: $accessPrimary;
}

body.path-group .group__content-wrapper__gray {
  background-color: $accessSecondary;
}

body.path-group .field__label {
  color: $accessPrimary;
}

body.path-group form.webform-submission-form label {
  color: $accessPrimary
}


body.path-group .group__content-wrapper .field--type-file {
  border-bottom: 1px solid $accessSecondary
}

body.path-group .group__content-wrapper .field--type-file .field__item {
  background-color: $accessSecondary;
  color: $accessPrimary;
}

body.path-group .group__content-wrapper .field--type-file .field__item a {
  color: $accessPrimary;
}

article.node--type-iepirkumi .field--name-body table thead {
  color: $accessPrimary;
  background: $accessSecondary
}

article.node--type-iepirkumi .field--name-body table thead td,
article.node--type-iepirkumi .field--name-body table thead th {
  border-bottom: 3px solid $accessSecondary;
}

article.node--type-iepirkumi .field--name-body table tbody tr {
  background: $accessSecondary;
}

article.node--type-iepirkumi .field--name-body table tbody tr td {
  border-color: transparent transparent $accessSecondary transparent
}

.product-two-columns {
  border-bottom: 2px solid $accessPrimary;
}

.product-two-columns .product-column-wrapper:not(:last-child) {
  border-bottom: 2px solid $accessSecondary
}

.product-two-columns .views-field-title:before,
.product-two-columns .views-field-title:after {
  background: $accessPrimary;
}

.product-two-columns .views-field-purchased-entity > .field__item:not(:last-child) .product-column-wrapper,
.product-two-columns .views-field-variations > .field__item:not(:last-child) .product-column-wrapper,
.product-two-columns .views-field-field-kategorija > .field__item:not(:last-child) .product-column-wrapper,
.product-two-columns .views-field-field-dzivnieka-veids > .field__item:not(:last-child) .product-column-wrapper,
.product-two-columns .views-field-field-slim > .field__item:not(:last-child) .product-column-wrapper,
.product-two-columns .field--name-variations > .field__item:not(:last-child) .product-column-wrapper {
  border-bottom: 2px solid $accessPrimary
}

.product-two-columns .views-field-purchased-entity .product-right-column,
.product-two-columns .views-field-variations .product-right-column,
.product-two-columns .views-field-field-kategorija .product-right-column,
.product-two-columns .views-field-field-dzivnieka-veids .product-right-column,
.product-two-columns .views-field-field-slim .product-right-column,
.product-two-columns .field--name-variations .product-right-column {
  border-left: 2px solid $accessPrimary;
}

.product-two-columns.open .views-field-title:before,
.product-two-columns.open .views-field-title:after {
  background-color: $accessPrimary;
}

.product-two-columns .views-field-remove-button:before {
  color: $accessPrimary;
  transition: all .3s ease;
}

.product-two-columns .views-field-remove-button:hover {
  &::before{
    color: $accessSecondary;
  }
}

.product-two-columns .views-field-remove-button .delete-order-item {
  background: $accessSecondary;
  color: $accessPrimary;
  outline: 2px solid $accessPrimary;
  transition: all .3s ease;
}

.product-two-columns .views-field-remove-button .delete-order-item:hover,
.product-two-columns .views-field-remove-button .delete-order-item:active,
.product-two-columns .views-field-remove-button .delete-order-item:focus {
  background: $accessPrimary;
  color: $accessSecondary;
}


.view-cenradis .product-two-columns.open .views-field-title:before,
.view-cenradis .product-two-columns.open .views-field-title:after,
.view-commerce-cart-form .product-two-columns.open .views-field-title:before,
.view-commerce-cart-form .product-two-columns.open .views-field-title:after {
  background-color: $accessPrimary;
}


body.page-node-type-petijums .wrap-details .field__label {
  color: $accessPrimary;
}

body.page-node-type-petijums .wrap-details .field--type-datetime {
  color: $accessPrimary
}

.view-commerce-cart-form .form-actions .empty-cart-button {
  background: $accessSecondary;
  color: $accessPrimary;
}

.view-commerce-cart-form .form-actions .empty-cart-button:hover,
.view-commerce-cart-form .form-actions .empty-cart-button:focus,
.view-commerce-cart-form .form-actions .empty-cart-button:active {
  background: $accessSecondary
}

.view-commerce-cart-form .link-print-pdf {
  background: $accessSecondary;
  color: $accessPrimary;
}

.view-commerce-cart-form .link-print-pdf:hover,
.view-commerce-cart-form .link-print-pdf:focus,
.view-commerce-cart-form .link-print-pdf:active {
  background: $accessSecondary
}

.field--name-body ul li {
  list-style-image: url("../images/#{$assetsFolder}/bior-bullet.svg");
}

.field--name-body ul li li {
  list-style-image: url("../images/#{$assetsFolder}/bior-bullet.svg")
}

.block-views-blockpublikacijas-autori-saraksts-block-1 h2 {
  color: $accessPrimary;
}

#block-biornodepublisheddate .clearfix {
  color: $accessPrimary
}

article .field--name-field-publikacijas-pilna-versija a {
  background-color: $accessSecondary;
  color: $accessPrimary;
}

article .field--name-field-publikacijas-pilna-versija a:hover {
  background-color: $accessSecondary
}

nav.pager .pager__item a {
  color: $accessPrimary;
}

nav.pager .pager__item.is-active a {
  border: 2px solid $accessPrimary
}


.fileAttachments > div {
  color: $accessPrimary;
}

.fileAttachments > div:hover {
  color: $accessPrimary
}

.beforeIcon:before {
  background-color: $accessSecondary;
}

.beforeIcon:after {
  background-color: $accessSecondary;
}

.view-header .selectYear {
  border-bottom: 2px solid $accessPrimary;
  background: url("../images/#{$assetsFolder}/bior-icons-select-arrow.svg") no-repeat 95% center
}

.view-header .selectYear option {
  background-color: $accessSecondary;
  color: $accessPrimary
}

article.node--type-iepirkumi .field--name-body table tbody tr td {
  border-bottom: 1px solid $accessPrimary;
}

.articleDate {
  color: $accessPrimary;
}


.messages--status {
  background: $accessSecondary;
}


.page-node-872 #block-bior-theme-content article em {
  color: $accessPrimary
}

div.small-caps {
  color: $accessPrimary;
}

.custom-webform-title {
  color: $accessPrimary;
}

.block.block-bior-previous-link .back-link {
  color: $accessPrimary;
}

.block-bior-menu #openMenu {
  color: $accessPrimary;
}

.block-bior-menu #openMenu #hamburger span {
  background: $accessPrimary;
}

.block-bior-menu.fixed #openMenu #hamburger {
  background-color: $accessPrimary;
}

.block-bior-menu.fixed #openMenu #hamburger span {
  background-color: $accessSecondary;
}

.block.block-bior-previous-link .back-link:before {
  background: url('../images/#{$assetsFolder}/bior-icon-arrow-left.svg') no-repeat center left;
}

.side-nav #closeSidebar {
  color: $accessPrimary;
}

.side-nav #closeSidebar:before {
  background: url('../images/#{$assetsFolder}/bior-sidebar-close.png');
}

.menu--main .menu__breadcrumbs a {
  color: $accessPrimary;
}

.block.block-views-blockvakances-block-1 .item-list li a {
  color: $accessPrimary;
}

.block-bior-menu #openMenu #hamburger {
  background-color: $accessSecondary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .moreInfo, .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .moreInfo {
  color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .moreInfo .box:before, .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .moreInfo .box:before {
  background-color: $accessPrimary;
}

.block.block-views-blockdoktori-un-doktoranti-block-4 .views-row .views-col .row-content .overlay .moreInfo .box:after, .block.block-views-blockdoktori-un-doktoranti-block-2 .views-row .views-col .row-content .overlay .moreInfo .box:after {
  background-color: $accessPrimary;
}

.block#block-bior-theme-branding,
footer .footer-two .left-side {
  .bior-custom-logo-svg-lv {
    background-image: url("../images/#{$assetsFolder}/bior_logo_lv.svg");
  }

  .bior-custom-logo-svg-en {
    background-image: url("../images/#{$assetsFolder}/bior_logo_en.svg");
  }

  .bior-custom-logo-svg-ru {
    background-image: url("../images/#{$assetsFolder}/bior_logo_ru.svg");
  }
}

.block#block-textresize .text-resize-popup,
.block#block-styleswitcher .style-switcher-popup {
  background: $accessSecondary;
  border: 1px solid $accessPrimary;
}

.block.block-text-resize a#text_resize_decrease,
.block.block-text-resize a#text_resize_reset,
.block.block-text-resize a#text_resize_increase {
  color: $accessPrimary;
}

.block#block-textresize .text-resize-button__aa svg,
.block#block-textresize .text-resize-button__arrow svg,
.block#block-styleswitcher .style-switcher-button__eye svg,
.block#block-styleswitcher .style-switcher-button__arrow svg,
.views-field-field-svg-ikona svg .st0,
.views-field-field-svg-ikona svg .st1,
.views-field-field-svg-ikona svg .st2,
.side-nav .sidebar-button-wrapper .sidebar-button svg {
  fill: $accessPrimary;
}

.block#block-styleswitcher .style-switcher-popup ul li a {
  border: 1px solid $accessPrimary;
}

#block-biorheaderbutton #bior-custom-block-header-button {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
  transition: all 0.5s ease;
}

#block-biorheaderbutton #bior-custom-block-header-button:hover {
  background-color: $accessPrimary;
  color: $accessSecondary;
}

div#sliding-popup, div#sliding-popup .eu-cookie-withdraw-banner, .eu-cookie-withdraw-tab {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border-top: 1px solid $accessPrimary;
}

#sliding-popup h1, #sliding-popup h2, #sliding-popup h3, #sliding-popup p, #sliding-popup label, #sliding-popup div, .eu-cookie-compliance-more-button, .eu-cookie-compliance-secondary-button, .eu-cookie-withdraw-tab {
  color: $accessPrimary;
}

.eu-cookie-compliance-banner .popup-content .eu-cookie-compliance-buttons button,
.eu-cookie-compliance-banner .popup-content .eu-cookie-compliance-buttons button:hover {
  background-color: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
}

.block-user-login-block .user-login-form-wrapper {
  border: 1px solid $accessPrimary;
}

.messages--status {
  color: $accessPrimary;
}

footer .footer-two {
  background: $accessSecondary;
  color: $accessPrimary;
  border: 2px solid $accessPrimary;
}

footer .footer-two .left-side,
footer .footer-two .left-side .icon-block__email a,
footer .footer-two .left-side .footer-sazinies-button a.sazinies {
  color: $accessPrimary;
}

footer .footer-two .left-side .footer-sazinies-button a.sazinies:after {
  background: url('../images/#{$assetsFolder}/bior-view-gallery.png') no-repeat center right;
}

footer .footer-two .left-side {
  .icon-block__address:before {
    background: url('../images/#{$assetsFolder}/footer-address.svg');
  }

  .icon-block__phone:before {
    background: url('../images/#{$assetsFolder}/footer-phone.svg');
  }

  .icon-block__email:before {
    background: url('../images/#{$assetsFolder}/footer-email.svg');
  }

  .icon-block__working:before {
    background: url('../images/#{$assetsFolder}/footer-working.svg');
  }
}

footer .follow-block a.facebook {
  border: 1px solid $accessPrimary;
  background: url('../images/#{$assetsFolder}/footer-facebook.svg');
}

footer .marker {
  background-image: url('../images/#{$assetsFolder}/bior-google-marker.svg');
}

.menu__item a:hover{
  text-decoration: underline;
}
