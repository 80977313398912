#block-printeraikona {
  display: inline-block;
  margin-left: 10px;
  vertical-align: bottom;

  #printPage {
    background: url('../images/bior-icon-print.svg') no-repeat center center;
    display: inline-block;
    width: 30px;
    height: 26px;
    cursor: pointer;
    vertical-align: bottom;
    margin-left: 10px;
  }
}
