.block-facet-blockslimibas {
  float: right;
  width: 50%;
  display: inline-block;

  h3 {
    color: color(white);
    font-weight: normal;
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 980px - 1) {
    clear: both;
    width: 100%;
    padding-left: 0;
  }

  .facets-widget-checkbox,
  .facets-widget-dropdown {
    margin: 20px 0;
    width: 100%;
    position: relative;
    min-height: 70px;

    & > ul {
      margin-top: 10px;
      min-height: 50px;
      background: color(white);
    }

    @media (min-width: 980px) {
      margin: 20px 0 10px 10px;
      width: calc(100% - 30px);
    }
  }

  .facets-widget-dropdown {
    .select2-container {
      width: 100%;
      min-height: 50px;

      .select2-selection {

      }
    }
  }

  .facet-empty {
    .facets-widget-checkbox,
    .facets-widget-dropdown {
      display: none;
      margin-top: 0;
    }
  }
}
