.block-system-branding-block {
  text-align: left;

  img {
    width: 340px;
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: 900px) {
  .block-system-branding-block {
    width: 100%;
    text-align: center;
  }
}
