footer {
  margin: 38px 0 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;

  .block {
    box-sizing: border-box;
  }

  .marker {
    background-image: url('../images/bior-google-marker.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 106px;
    height: 63px;
    cursor: pointer;
  }

  .footer-two {
    background: #014F52;
    padding: 0;
    margin: 20px 0;

    .left-side {
      color: #FFF;
      padding: 26px 0 40px 40px;
      line-height: 21px;
      margin-bottom: 20px;
      font-size: 15px;

      .icon-block {
        padding-left: 40px;

        &__address {
          position: relative;
          padding: 5px 0;

          &:before {
            content: '';
            display: block;
            background: url("../images/footer-address.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 15px;
            height: 21px;
            position: absolute;
            left: -30px;
            top: 5px;
          }
        }

        &__phone {
          position: relative;
          padding: 5px 0;

          &:before {
            content: '';
            display: block;
            background: url("../images/footer-phone.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -30px;
            top: 5px;
          }
        }

        &__email {
          position: relative;
          padding: 5px 0 30px;

          a {
            color: #FFF;
            text-decoration: none;
          }

          &:before {
            content: '';
            display: block;
            background: url("../images/footer-email.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 20px;
            height: 19px;
            position: absolute;
            left: -30px;
            top: 5px;
          }
        }

        &__working {
          position: relative;
          padding: 0;
          line-height: 31px;

          @media (min-width: 800px) {
            line-height: 32px;
          }

          &:before {
            content: '';
            display: block;
            background: url("../images/footer-working.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: 19px;
            height: 19px;
            position: absolute;
            left: -30px;
            top: 5px;
          }
        }
      }

      .bior-custom-logo-svg {
        display: block;
        width: 195px;
        max-width: 100%;
        height: auto;
        min-height: 65px;
        margin: 0 auto;
        text-align: center;
        background-color: transparent;
        background-position: center top;
        background-repeat: no-repeat;
      }

      @media (min-width: 1220px) {
        width: auto;

        .bior-custom-logo-svg {
          margin: 0;
        }
      }

      .bior-custom-logo-svg-lv {
        background-image: url("../images/white_accessibility/bior_logo_lv.svg");
      }

      .bior-custom-logo-svg-en {
        background-image: url("../images/white_accessibility/bior_logo_en.svg");
      }

      .bior-custom-logo-svg-ru {
        background-image: url("../images/white_accessibility/bior_logo_ru.svg");
      }

      .footer-sazinies-button {
        margin: 30px 0 0 0;
        display: block;
        padding-left: 40px;

        a {
          display: inline-block;
          vertical-align: top;
          color: #FFF;
          font-size: 15px;
          position: relative;
          padding-right: 30px;

          &.sazinies {
            box-sizing: border-box;
            text-decoration: none;

            &:after {
              content: '';
              display: inline-block;
              top: 8px;
              width: 18px;
              height: 10px;
              position: absolute;
              left: 90%;
              background: url("../images/bior-view-gallery-white.png") no-repeat center right;
              transition: width 0.3s ease;
            }
            &:hover:after {
              width: 35px;
            }
          }
        }
      }
    }

    .region-footer-left {
      margin: 0 auto;
      width: 220px;
    }

    .getRoute {
      position: absolute;
      bottom: 20px;
      left: 50%;
      margin-left: -100px;

      a {
        display: block;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 2px;
        background: #fff;
        color: color(dark-green);
        padding: 10px 25px;
        text-decoration: none;
        transition: background-color 0.3s ease, color 0.3s ease;

        &:hover {
          background-color: color(light-blue);
          color: #fff;
        }
      }
    }

    #map {
      height: 500px;
      width: 100%;
    }
  }

  .footer-three {
    border-top: 2px solid color(light-blue);

    .block {
      box-sizing: border-box;
      margin: 20px 0;
    }
  }

  .footer-bottom {
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 800px) {
      padding-bottom: 109px;
    }

    &__inner-container {
      display: flex;
      flex-direction: column;

      @media (min-width: 800px) {
        flex-direction: row;
      }

      justify-content: space-between;

      .follow-block {
        display: flex;
        align-content: center;
        align-items: center;
        margin-left: 45px;

        .facebook {
          margin-left: 30px;

          svg {
            width: 54px;
            height: 54px;
          }
        }
      }

      .extraInfo {
        font-size: 15px;
        color: #000;

        @media (min-width: 800px) {
          text-align: right;
        }

        a {
          color: #000;
          text-decoration: none;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 800px) {
  footer {
    .footer-two {
      display: flex;
      align-items: center;
      margin: 0;

      .left-side {
        width: 420px;
        line-height: 1.5em;
        margin-bottom: 0;
      }

      .right-side {
        max-width: 880px;
        width: calc(100% - 420px);
      }

      .getRoute {
        top: 20px;
        right: 20px;
        margin-left: 0;
        left: inherit;
        bottom: inherit;
      }
    }

    .footer-three {
      margin: 100px auto 0 auto;
      padding: 50px 20px;

      .block {
        width: 33.33%;
        float: left;
        margin: 0;
      }

    }
  }
}

footer .follow-block a.facebook {
  display: inline-block;
  width: 54px;
  height: 54px;
  background: url('../images/footer-facebook.svg');
}
