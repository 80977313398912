.block {
  &.block-views-blockadministracijas-kontakti-block-1,
  &.block-views-blockadministracijas-kontakti-block-3,
  &.block-views-blockkontakti-saraksts-block-1 {
    .view-header {
      .views-field-field-kontakts-vards-uzvards {
        font-size: 16px;
        padding: 0;
        margin: 0;
      }
    }

    .views-row {
      position: relative;
      box-sizing: border-box;
      margin-bottom: 30px;
      padding: 0 10px;
      font-weight: 300;

      .views-field {
        margin-bottom: 10px;

        &.views-field-field-kontakts-vards-uzvards {
          padding: 0 10px;
          position: relative;
          height: 6em;
          font-weight: 400;
          margin-bottom: 20px;

          h3 {
            position: absolute;
            bottom: 1em;
            font-size: 2em;
            margin: 0;
            font-weight: 400;
            max-width: 360px;
            display: block;
            box-sizing: border-box;
            padding: 0 10px;
          }

          .grads {
            position: absolute;
            bottom: 10px;
            font-style: italic;
            font-size: 1em;
            margin: 0 auto;
            font-weight: 400;
            max-width: 360px;
            display: block;
            box-sizing: border-box;
            padding: 0 10px;
          }

          &:after {
            content: ' ';
            background-color: #88d1ce;
            width: 100%;
            position: absolute;
            height: 2px;
            bottom: 0;
            left: 0;
            transition: all 0.3s ease;
          }
        }

        &.views-field-field-paraugu-transportesana {
          font-weight: 400;
          line-height: 1.2;
        }

        &.views-field-title {
          font-weight: 600;
        }

        .views-label {
          font-weight: 400;
        }

        .field-content {
          margin: 5px auto;
          max-width: 360px;
          display: block;
          box-sizing: border-box;
          padding: 0 10px;
        }
      }

      &:hover {
        .views-field-title,
        .views-field-field-kontakts-vards-uzvards {
          &:after {
            transform: scaleY(3);
          }
        }
      }
    }
  }

  &.block-views-blockkontakti-saraksts-block-1 {
    .views-row {
      .views-field {
        &.views-field-title {
          padding: 0 10px;
          position: relative;
          height: 6em;
          font-weight: 400;
          margin-bottom: 20px;

          .field-content {
            max-width: 360px;
            margin: 5px auto;
            font-size: 2em;
            position: absolute;
            bottom: 10px;
            padding: 0 10px;
          }

          &:after {
            content: ' ';
            background-color: #88d1ce;
            width: 100%;
            position: absolute;
            height: 2px;
            bottom: 0;
            left: 0;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .block {
    &.block-views-blockadministracijas-kontakti-block-1,
    &.block-views-blockadministracijas-kontakti-block-3,
    &.block-views-blockkontakti-saraksts-block-1 {
      .views-row {
        float: left;
        width: 50%;

        &:nth-child(2n+1) {
          clear: left;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .block {
    &.block-views-blockadministracijas-kontakti-block-1,
    &.block-views-blockadministracijas-kontakti-block-3,
    &.block-views-blockkontakti-saraksts-block-1 {
      .views-row {
        float: left;
        width: 33.33%;

        &:nth-child(2n+1) {
          clear: none;
        }

        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
}
