body.page-node-type-petijums {
  .wrap-details {
    .field {
      padding: 0;
      margin: 30px auto 40px auto;
      text-align: left;
      font-size: 0.875em;
      font-weight: 300;
    }

    .field__label {
      margin: 0 0 5px 0;
      color: #b4b4b4;
      padding: 0;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 300;
    }

    .field--type-datetime {
      font-size: 0.875em;
      text-transform: lowercase;
      color: #b4b4b4;
    }
  }
}
