.webform-submission-form {
  background-color: #88d1ce;
  margin: 15px 0;
  padding: 20px;

  input, textarea {
    width: 100%;
    padding: 15px 20px;
    border: 2px solid #fff;
    outline: none;
    color: #004f51;
    background-color: transparent;
    resize: none;

    &::placeholder {
      color: #FFF;
      font-size: 14px;
    }
  }

  .groupInfo {
    margin: 0 auto 20px auto;
    border: none;
    border-bottom: 1px solid #fff;
    padding: 0 0 20px 0;
  }

  .form-type-radio,
  .form-type-checkbox {
    input {
      width: auto;
      display: none;
    }

    input[type=radio] + label,
    input[type=checkbox] + label {
      &:before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 20px;
        box-sizing: border-box;
        margin: 0 10px 0 0;
        vertical-align: middle;
        border: 2px solid #fff;
        padding-top: 2px;
        text-align: center;
        font-size: 13px;
        cursor: pointer;
        line-height: 100%;
      }
    }

    input[type=radio]:checked + label,
    input[type=checkbox]:checked + label {
      &:before {
        content: 'X';
      }
    }
  }

  label {
    color: #FFF;
    font-size: 16px;

    &.error {
      font-size: smaller;
      color: #b25d5d;
    }
  }

  select {
    width: 100%;
    padding: 15px 20px;
    border: 2px solid #fff;
    outline: none;
    color: #004f51;
    background-color: transparent;
    resize: none;

  }

  .intl-tel-input {
    width: 100%;
  }

  em {
    color: #FFF;
    font-size: smaller;
  }

  .messages {
    padding: 45px 20px 15px 31px;
    background-color: transparent;
    border-color: transparent;
    color: #FFF;
  }

  .form-managed-file {
    position: relative;
    padding-left: 25px;
    max-width: 100%;
    overflow: hidden;
    word-break: break-word;
    color: #FFF;

    .button-action {
      padding: 0;
      margin: 0;
    }

    .form-submit {
      background-image: url("../images/bior-sidebar-close.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 15px;
      height: 15px;
      color: transparent;
      float: left;
      padding: 0;
      margin: 0;
      border: transparent;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .webform-file-button {
    &:before {
      content: '';
      background-image: url("../images/bior-icon-upload.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      vertical-align: middle;
      box-sizing: border-box;
      margin-right: 10px;
      cursor: pointer;
      color: #FFF;
    }
  }

  .form-actions {
    text-align: center;

    input {
      width: auto;

      &.form-submit {
        background-color: #b25d5d;
        color: #fff;
        letter-spacing: 1px;
        border: none;
        font-weight: 700;
        padding: 15px 80px;
        text-transform: uppercase;
        outline: none;
        transition: background-color 0.3s;

        &:hover {
          background-color: #824545;
        }
      }
    }
  }
}

@media (min-width: 800px) {
  .webform-submission-form {
    padding: 100px;

    body.page-node-type-vakance & {
      padding: 0px;
    }

    .form-actions {
      margin: 50px 0 0 0;
    }
  }
}
